import { Route, Routes } from 'react-router-dom';

import Home from './Home';
import Start from './Start';
import Learn from './Learn';
import Registration from './Registration';
import ThankYou from './ThankYou';
import Interview from './Interview';
import DSAViewer from './DSAViewer';
import RegistrationRedirect from './RegistrationRedirect';
import NotFound from './NotFound.js';
import PCTAlert from './PCTAlert.js';
import PreviewInterview from './PreviewInterview.js';

function Main() {
  const routes = [
    { path: '/', component: Home, exact: true },
    { path: '/home', component: Home },
    { path: '/learn', component: Learn },
    { path: '/onboard', component: Start },
    {
      path: '/onboard/:registrationTypeFromURL/previewinterview',
      component: PreviewInterview,
    },
    {
      path: '/onboard/:registrationTypeFromURL/previewinterview',
      component: PreviewInterview,
    },
    {
      path: '/onboard/:registrationTypeFromURL/registration',
      component: Registration,
    },
    { path: '/onboard/:registrationTypeFromURL/thankyou', component: ThankYou },
    {
      path: '/onboard/:registrationTypeFromURL/interview',
      component: Interview,
    },
    {
      path: '/onboard/:registrationTypeFromURL/dsa',
      component: DSAViewer,
    },
    { path: '/registration', component: RegistrationRedirect },
    { path: '/registrationconsumer', component: RegistrationRedirect },
    { path: '/registrationprovider', component: RegistrationRedirect },
  ];

  return (
    <main id="mainRoutePaths" className="py-2 px-4">
      <PCTAlert />
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            component={route.component}
            element={<route.component />}
            exact={route.exact}
          />
        ))}
        {/* Default fallback route */}
        <Route path="*" element={<NotFound />} />
        <Route path="onboard/*" element={<NotFound />} />
      </Routes>
    </main>
  );
}

export default Main;
