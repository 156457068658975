import { detect } from 'detect-browser';

const detectPlatform = () => {
  let browser = detect();

  if (browser) {
    browser.os = browser.os ?? 'unknown';
    browser.version = browser.version ?? 'unknown';
    browser.name = browser.name ?? 'unknown';
  } else {
    browser = { os: 'unknown', version: 'unknown', name: 'unknown' };
  }

  return browser;
};

export default detectPlatform;
