import $ from 'jquery';

export const upperCaseString = (str) => {
  const sStr = str.toString(); // ensure string, not array
  return sStr[0].toUpperCase() + sStr.slice(1);
};
// simpler alias
export const uc1 = upperCaseString;
window.uc1 = upperCaseString;

export const getPCTStatusFromSubmissionData = (
  submissionData,
  registrationType = '',
) => {
  let filterOutRegistrationType = '';

  if (registrationType === 'provider') {
    filterOutRegistrationType = 'consumer';
  } else if (registrationType === 'consumer') {
    filterOutRegistrationType = 'provider';
  }

  if (filterOutRegistrationType !== '') {
    return Object.entries(submissionData)
      .filter(([key]) => !key.startsWith(filterOutRegistrationType))
      .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
      .join('\n');
  } else {
    return Object.entries(submissionData)
      .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
      .join('\n');
  }
};

export const scrollToTop = () => {
  $('html, body').animate({ scrollTop: 0 });
};

export const remapRedmineSettingsObjectFields = (redmineSettingsObject) => {
  const remappedRedmineSettingsObject = {};

  const transformed_projects = {};

  redmineSettingsObject.projects.forEach((project) => {
    const transformed_issue_custom_fields = {};
    project.issue_custom_fields.forEach((field) => {
      transformed_issue_custom_fields[field.name] = {
        id: field.id,
      };
    });

    const transformed_trackers = {};
    project.trackers.forEach((tracker) => {
      transformed_trackers[tracker.name] = { id: tracker.id.toString() };
    });

    transformed_projects[project.name] = {
      id: project.id.toString(),
      issue_custom_fields: transformed_issue_custom_fields,
      trackers: transformed_trackers,
    };
  });

  remappedRedmineSettingsObject.projects = transformed_projects;

  return remappedRedmineSettingsObject;
};

export const filterUnrelatedFields = (obj, filters) => {
  const regexPatterns = filters.map((filter) => {
    const pattern = filter
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      .replace(/\\\*/g, '.*');
    // We can disable the Semgrep check for non-literal regex because filters are not user created. They are hard coded in the application.
    // nosemgrep: eslint.detect-non-literal-regexp
    return new RegExp(`^${pattern}$`);
  });

  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key]) => !regexPatterns.some((regex) => regex.test(key)),
    ),
  );
};
