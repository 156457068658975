import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardingDataContext from './OnboardingDataContext';

const RegistrationRedirect = () => {
  const { registrationType } = useContext(OnboardingDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    let redirectPath;

    redirectPath = `/onboard/${registrationType}/registration`;

    navigate(redirectPath);
  }, [registrationType, navigate]);
};

export default RegistrationRedirect;
