import { filterUnrelatedFields } from './global-utils.js';

export const exportToJSONFile = ({
  formData,
  formType,
  pctVersion,
  registrationType,
  formVersion,
  filters,
}) => {
  if (formData && formData.data != undefined) {
    const metadata = {
      appName: 'Policy Capture Tool',
      version: pctVersion,
      registrationType: registrationType,
      formVersion: formVersion.replace('Form Version: ', ''),
      dataType: formType,
      exportedAt: new Date().toISOString(),
    };

    const filteredFormData = filterUnrelatedFields(formData.data, filters);

    const jsonString = JSON.stringify({
      _metadata: metadata,
      data: filteredFormData,
    });

    const blob = new Blob([jsonString], { type: 'application/json' });

    const link = document.createElement('a');

    let fileName = registrationType + `_${formType.toLowerCase()}`;

    fileName = filteredFormData[`${registrationType}_organization`]
      ? fileName + '_' + filteredFormData[`${registrationType}_organization`]
      : fileName;

    fileName = filteredFormData[`${registrationType}_porMajorSystem`]
      ? fileName + '_' + filteredFormData[`${registrationType}_porMajorSystem`]
      : fileName;

    fileName = filteredFormData[`${registrationType}_systemName`]
      ? fileName + '_' + filteredFormData[`${registrationType}_systemName`]
      : fileName;

    link.download = fileName;

    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
};

export const importFromJSONFile = () => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json';

    input.click();

    input.addEventListener('change', (event) => {
      const file = event.target.files[0];

      if (!file) {
        reject(new Error('No file selected'));
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const parsedData = JSON.parse(e.target.result);
          resolve(parsedData);
        } catch (error) {
          reject(new Error('Failed to parse JSON'));
        }
      };

      reader.onerror = () => reject(new Error('Failed to read file'));

      reader.readAsText(file);
    });
  });
};
