import React, { useState, useEffect, useContext } from 'react';
import { Form } from '@formio/react';
import { scrollToTop } from '../global-utils.js';
import { fetchJson } from '../fetch-json.js';
import Indicator from './Indicator.js';
import FormLoadError from './FormLoadError.js';
import CommonSettingsContext from './CommonSettingsContext';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const ThankYou = () => {
  const { commonSettingsData } = useContext(CommonSettingsContext);

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [form, setForm] = useState(null);
  const [errorLoadingForm, setErrorLoadingForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleThankYouRender = () => {
    let thankYouElement = document.getElementById('thankYouMessage');

    let replacedThankYouMessage = state.thankYouMessage.replace(
      '{{redmineIssueID}}',
      state.redmineIssueID,
    );

    thankYouElement.innerHTML = '<div>' + replacedThankYouMessage + '</div>';
    scrollToTop();
  };

  const handleButtonClick = () => {
    navigate('/onboard/provider/registration');
  };

  // Fetch and render formio form
  useEffect(() => {
    if (state === null) {
      navigate('/onboard/provider/registration');
    } else {
      const fetchData = async () => {
        try {
          const [form] = await Promise.all([
            fetchJson(commonSettingsData['thankYouFile']),
          ]);

          setForm(form);
          setLoading(false);
          setErrorLoadingForm(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
          setErrorLoadingForm(true);
        }
      };

      fetchData();
    }
  }, []);

  // Render a loading message if the data is not loaded yet.
  if (loading) return <Indicator />;

  return errorLoadingForm ? (
    <FormLoadError />
  ) : (
    <Container>
      <Row>
        <Form form={form} onRender={handleThankYouRender} />
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="auto">
          <Button
            variant="btn btn-primary custom-btn-primary"
            onClick={handleButtonClick}
            data-testid="returnToRegistrationFormBtn"
          >
            Return to Form
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYou;
