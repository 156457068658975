import React, { createContext, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const ModalAlertContext = createContext({
  showModalAlert: () => {},
});

export const ModalAlertQueue = ({ children }) => {
  const [queue, setQueue] = useState([]);
  const [currentAlert, setCurrentAlert] = useState(null);

  useEffect(() => {
    if (queue.length > 0 && currentAlert === null) {
      setCurrentAlert(queue[0]);
      setQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [queue, currentAlert]);

  const showModalAlert = (alert) => {
    setQueue((prevQueue) => [...prevQueue, alert]);
  };

  const handleClose = () => {
    setCurrentAlert(null);
  };

  /* This function is needed for formatting the text in the DoD Warning banner. 
     It has a bulleted list that requires special handling to display. This function is 
     pretty much designed to only handel it and the simple case of a single paragraph. 
     Behavior is undefined for multiple paragraphs with or without their own lists.
     TODO: Expand functionality of this if case arises where multiple paragraphs needed.
  */
  const formatBulletedText = (text) => {
    const paragraphs = text.split('\n');
    const formattedParagraphs = [];
    for (let i = 0; i < paragraphs.length; i++) {
      const bulletIndex = paragraphs[i].indexOf('•');
      if (bulletIndex === -1) {
        formattedParagraphs.push(<p key={i}>{paragraphs[i]}</p>);
      } else {
        const postBulletText = paragraphs[i].substring(bulletIndex + 1);
        formattedParagraphs.push(<li key={`${i}-bullet`}>{postBulletText}</li>);
      }
    }
    if (formattedParagraphs.length === 1) {
      return formattedParagraphs[0];
    } else {
      return (
        <div>
          {formattedParagraphs[0]}
          <ul>{formattedParagraphs.slice(1)}</ul>
        </div>
      );
    }
  };

  return (
    <ModalAlertContext.Provider value={{ showModalAlert }}>
      {children}
      {currentAlert && (
        <Modal
          id={currentAlert.id}
          show={true}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={
            currentAlert.backdrop !== undefined ? currentAlert.backdrop : true
          }
        >
          <Modal.Header
            closeButton={
              currentAlert.closeButton !== undefined
                ? currentAlert.closeButton
                : true
            }
          >
            <Modal.Title>{currentAlert.modalAlertTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {formatBulletedText(currentAlert.modalAlertBody)}
          </Modal.Body>
          <Modal.Body>{currentAlert.modalBody}</Modal.Body>
          <Modal.Footer>
            {currentAlert.buttons.map((button, index) => (
              <Button
                id={button.id}
                data-testid={button.id}
                key={index}
                variant={button.variant ? button.variant : 'primary'}
                onClick={() => {
                  button.onClick ? button.onClick() : null;
                  handleClose();
                }}
              >
                {button.label}
              </Button>
            ))}
          </Modal.Footer>
        </Modal>
      )}
    </ModalAlertContext.Provider>
  );
};

ModalAlertQueue.propTypes = {
  children: PropTypes.node.isRequired,
};
