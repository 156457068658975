import { useState, useContext, useEffect } from 'react';

import AppLoadScreen from './AppLoadScreen.js';
import Header from './Header';
import HelpButton from './HelpButton.js';
import Main from './Main';
import ClassificationBanner from './ClassificationBanner.js';
import ConfigChoice from './ConfigChoice.js';
import CommonSettingsContext from './CommonSettingsContext';
import EnvironmentContext from './EnvironmentContext';
import PCTAlertContext from './PCTAlertContext.js';
import RedmineConfigContext from './RedmineConfigContext.js';
import { ModalAlertContext } from './ModalAlertQueue.js';
import { OnboardingStateProvider } from './OnboardingStateContext';
import { OnboardingDataProvider } from './OnboardingDataContext';

import { queryRedmine } from '../redmine-utils';
import { remapRedmineSettingsObjectFields } from '../global-utils';

function App() {
  const consentBannerAckedKey = 'consentBanner';

  const [alert, setAlert] = useState({
    show: false,
    variant: '',
    heading: '',
    text: '',
    info: '',
    buttons: [],
  });

  const consentBannerAckedFromStorage = JSON.parse(
    localStorage.getItem(consentBannerAckedKey),
  );

  const [hasUserAckedConsentBanner, setHasUserAckedConsentBanner] = useState(
    consentBannerAckedFromStorage || false,
  );
  const [showApp, setShowApp] = useState(false);

  const { showModalAlert } = useContext(ModalAlertContext);
  const {
    environmentData,
    isEnvironmentDataLoaded,
    isQueryingEnvironmentDataLoaded,
  } = useContext(EnvironmentContext);
  const {
    commonSettingsData,
    isCommonSettingsDataLoaded,
    isQueryingCommonSettingDataLoaded,
  } = useContext(CommonSettingsContext);

  const {
    redmineConfigs,
    setRedmineSettings,
    isRedmineUnreachable,
    setIsRedmineUnreachable,
    isRedmineConfigsLoaded,
    isLoadingRedmineConfigs,
  } = useContext(RedmineConfigContext);

  const setConsentBannerAcked = () => {
    localStorage.setItem(consentBannerAckedKey, JSON.stringify(true));
    setHasUserAckedConsentBanner(true);
  };

  useEffect(() => {
    if (
      isEnvironmentDataLoaded &&
      isCommonSettingsDataLoaded &&
      !hasUserAckedConsentBanner &&
      environmentData.dodLoginBannerEnabled
    ) {
      showModalAlert({
        modalAlertTitle: commonSettingsData.dodLoginBannerTitle,
        modalAlertBody: commonSettingsData.dodLoginBannerMessage,
        id: 'dodBanner',
        backdrop: 'static',
        closeButton: false,
        buttons: [
          {
            label: commonSettingsData.dodLoginBannerButtonLabel,
            id: 'dodBannerAckButton',
            variant: 'primary',
            onClick: setConsentBannerAcked,
          },
        ],
      });
    }
  }, [isEnvironmentDataLoaded, isCommonSettingsDataLoaded]);

  useEffect(() => {
    if (
      isCommonSettingsDataLoaded &&
      isRedmineConfigsLoaded &&
      isEnvironmentDataLoaded
    ) {
      const fetchRedmineSettings = async () => {
        let redmineSettingsObject = {};

        try {
          redmineSettingsObject = await queryRedmine(
            redmineConfigs.redmineApiProjectDataEndpoint,
          );
        } catch (error) {
          console.error(
            error + ': Failed to retrieve project data from Redmine.',
          );
          setIsRedmineUnreachable(true);
          return;
        }

        try {
          if (redmineSettingsObject && redmineSettingsObject.projects) {
            const redmineRemappedData = remapRedmineSettingsObjectFields(
              redmineSettingsObject,
            );
            setRedmineSettings(redmineRemappedData);
          } else {
            throw new Error('Redmine data cannot be found');
          }
        } catch (error) {
          console.error(error + ': Failed to remap Redmine data');
          setIsRedmineUnreachable(true);
        }
      };

      const fetchDevRedmineSettings = async () => {
        try {
          const timestamp =
            Math.floor(new Date().getTime() / (30 * 60 * 1000)) *
            (30 * 60 * 1000);

          const response = await fetch(
            commonSettingsData.devRedmineSettingsPath + '?cb=' + timestamp,
          );
          const redmineSettingsObject = await response.json();

          if (redmineSettingsObject && redmineSettingsObject.projects) {
            const redmineRemappedData = remapRedmineSettingsObjectFields(
              redmineSettingsObject,
            );
            setRedmineSettings(redmineRemappedData);
          } else {
            throw new Error('Redmine developer data cannot be found');
          }
        } catch (error) {
          console.error(error + ': Failed to remap developer Redmine data');
          setIsRedmineUnreachable(true);
        }
      };

      if (environmentData.environment === 'dev-no-redmine') {
        fetchDevRedmineSettings();
      } else {
        fetchRedmineSettings();
      }
    }

    if (
      !isQueryingEnvironmentDataLoaded &&
      !isLoadingRedmineConfigs &&
      !isQueryingCommonSettingDataLoaded
    ) {
      setShowApp(
        !isRedmineUnreachable &&
          isEnvironmentDataLoaded &&
          isCommonSettingsDataLoaded,
      );
    }
  }, [
    isRedmineUnreachable,
    isEnvironmentDataLoaded,
    isCommonSettingsDataLoaded,
    isQueryingEnvironmentDataLoaded,
    isQueryingCommonSettingDataLoaded,
    isQueryingEnvironmentDataLoaded,
    isRedmineConfigsLoaded,
    isLoadingRedmineConfigs,
  ]);

  return (
    <div>
      {process.env.NODE_ENV === 'development' ? <ConfigChoice /> : null}
      <ClassificationBanner />
      <PCTAlertContext.Provider value={[alert, setAlert]}>
        <OnboardingStateProvider>
          <OnboardingDataProvider>
            <Header />
            {showApp ? (
              <div>
                <Main />
                <HelpButton />
              </div>
            ) : (
              <AppLoadScreen
                isAppDataLoading={
                  isQueryingEnvironmentDataLoaded || isLoadingRedmineConfigs
                }
              />
            )}
          </OnboardingDataProvider>
        </OnboardingStateProvider>
      </PCTAlertContext.Provider>
    </div>
  );
}

export default App;
