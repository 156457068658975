import { createContext } from 'react';

const PCTAlertContext = createContext({
  show: false,
  variant: '',
  heading: '',
  text: '',
  textComponent: null,
  info: '',
  buttons: [],
});

export default PCTAlertContext;
