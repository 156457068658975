import { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Form } from '@formio/react';
import { useNavigate, useParams } from 'react-router-dom';

import RegistrationNavBar from './RegistrationNavBar';
import Footer from './Footer';
import Indicator from './Indicator.js';
import FormLoadError from './FormLoadError.js';
import PCTAlertContext from './PCTAlertContext.js';
import OnboardingStateContext from './OnboardingStateContext';
import OnboardingDataContext from './OnboardingDataContext';
import EnvironmentContext from './EnvironmentContext';
import CommonSettingsContext from './CommonSettingsContext';
import RedmineConfigContext from './RedmineConfigContext.js';

import { postRedmine } from '../redmine-utils.js';
import {
  scrollToTop,
  getPCTStatusFromSubmissionData,
  filterUnrelatedFields,
} from '../global-utils.js';
import { fetchJson } from '../fetch-json.js';
import {
  exportToJSONFile,
  importFromJSONFile,
} from '../export-import-form-data.js';
import detectPlatform from '../detect-platform.js';

const Registration = () => {
  const formInstanceRef = useRef(null);
  const isMountedRef = useRef(null);

  const [, setAlert] = useContext(PCTAlertContext);
  const { environmentData, pctVersion, userDN } =
    useContext(EnvironmentContext);
  const { commonSettingsData } = useContext(CommonSettingsContext);
  const { onboardingStateMatrix, setOnboardingStateMatrix } = useContext(
    OnboardingStateContext,
  );

  const {
    registrationData,
    setRegistrationData,
    setInterviewData,
    interviewData,
    setRegistrationType,
  } = useContext(OnboardingDataContext);

  const { redmineSettings, redmineConfigs, isRedmineUnreachable } =
    useContext(RedmineConfigContext);

  const [form, setForm] = useState(null);
  const [errorLoadingForm, setErrorLoadingForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showResetFormDialog, setShowResetFormDialog] = useState(false);
  const [formVersion, setFormVersion] = useState('');

  // State variable to store jsonAnswers.data
  const [submissionData, setSubmissionData] = useState({
    data: {},
  });
  const [baselineFiles, setBaselineFiles] = useState(null);

  const { registrationTypeFromURL } = useParams();

  const redmineSettingsRef = useRef(redmineSettings);
  const environmentDataRef = useRef(environmentData);
  const pctVersionRef = useRef(pctVersion);
  const userDNRef = useRef(userDN);
  const redmineConfigsRef = useRef(redmineConfigs);
  const commonSettingsDataRef = useRef(commonSettingsData);
  const submissionDataRef = useRef(submissionData);
  const formVersionRef = useRef(formVersion);

  let isSubmitting = false;
  let originalSubmitButtonContent;

  const handleCloseFormDialog = () => setShowResetFormDialog(false);
  const handleShowClearFormDialog = () => setShowResetFormDialog(true);

  const navigate = useNavigate();

  const alertText =
    'Are you sure you want to reset the ' +
    registrationTypeFromURL +
    ' registration form?';

  const filterString =
    registrationTypeFromURL === 'consumer' ? 'provider*' : 'consumer*';

  const filters = [
    filterString,
    'saveAndProceed',
    'os',
    'pctVersion',
    'networkLabel',
    'browser',
    'registrationData',
    'jsonDataDump',
    'pctStatus',
  ];

  const navigateNextComponent = ({ isInitialRegistration, redmineIssueID }) => {
    if (isInitialRegistration) {
      modifyStateMatrix([
        { row: 1, col: 0, value: 0 }, // Do not mark registration as completed for initial provider visit
        { row: 1, col: 1, value: 0 },
        { row: 1, col: 2, value: 0 },
      ]);

      navigate('/onboard/provider/thankyou', {
        state: {
          thankYouMessage:
            commonSettingsDataRef.current.thankYouMessageWithRedmineIssueId,
          redmineIssueID: redmineIssueID,
        },
      });
    } else {
      if (registrationTypeFromURL === 'consumer') {
        modifyStateMatrix([
          { row: 0, col: 0, value: 1 },
          { row: 0, col: 1, value: 0 },
          { row: 0, col: 2, value: 0 },
        ]);
      } else {
        modifyStateMatrix([
          { row: 1, col: 0, value: 1 },
          { row: 1, col: 1, value: 0 },
          { row: 1, col: 2, value: 0 },
        ]);
      }

      navigate(`/onboard/${registrationTypeFromURL}/interview`);
    }
    scrollToTop();
  };

  const modifyStateMatrix = (modifications) => {
    const updatedMatrix = [...onboardingStateMatrix];

    for (const { row, col, value } of modifications) {
      updatedMatrix[row][col] = value;
    }

    setOnboardingStateMatrix(updatedMatrix);
  };

  const postRedmineIssue = async (rmIssue) => {
    const response = await postRedmine(
      {
        issue: rmIssue,
        redmineIssuesEndpoint:
          redmineConfigsRef.current.redmineApiIssuesEndpoint,
      },
      environmentDataRef.current.environment,
    );

    if (!response.ok) {
      if (response.status) {
        throw new Error(response.status);
      }
    }

    const redmineIssueDetails = await response.json();

    return redmineIssueDetails.issue.id;
  };

  const createRedmineIssue = (registrationFormData, redmineIssueFields) => {
    // Phone numbers have masks applied to them. We want to modify the phone number string to prepend this mask to it.
    const repPhone =
      redmineIssueFields[`${registrationTypeFromURL}_repPhone_field`].value;
    const repPhoneMask =
      redmineIssueFields[`${registrationTypeFromURL}_repPhone_field`].maskName;
    const techPocPhone =
      redmineIssueFields[`${registrationTypeFromURL}_techPocPhone_field`].value;
    const techPocPhoneMask =
      redmineIssueFields[`${registrationTypeFromURL}_techPocPhone_field`]
        .maskName;

    redmineIssueFields[`${registrationTypeFromURL}_repPhone`] =
      `${repPhoneMask}: ${repPhone}`;
    redmineIssueFields[`${registrationTypeFromURL}_techPocPhone`] =
      `${techPocPhoneMask}: ${techPocPhone}`;

    const platform = detectPlatform();

    redmineIssueFields['browser'] = platform.name + ' v' + platform.version;
    redmineIssueFields['os'] = platform.os;

    redmineIssueFields['pctVersion'] = pctVersionRef.current;

    redmineIssueFields['networkLabel'] =
      environmentDataRef.current.networkLabel;

    // Get the PCT Status but don't put it into data until after dump.
    const pctStatus = getPCTStatusFromSubmissionData(
      redmineIssueFields,
      registrationTypeFromURL,
    );

    // We must add the JSON dump before pctStatus and registrationData are added to this object,
    // or the dump will be a lot of additional repeated data.
    const jsonDump = JSON.stringify(redmineIssueFields, null, 2);
    redmineIssueFields['jsonDataDump'] = jsonDump;

    redmineIssueFields['pctStatus'] = pctStatus;

    // Use the unmodified registrationFormData for the registrationData felid.
    redmineIssueFields['registrationData'] = JSON.stringify(
      registrationFormData,
      null,
      2,
    );

    redmineIssueFields['submitterName'] = userDNRef.current;

    let project_name = '';
    let project_id = 0;

    if (environmentDataRef.current.environment === 'dev-no-redmine') {
      project_name = 'Dev Local Test Data';
      project_id = redmineSettingsRef.current.projects[project_name].id;
    } else {
      project_name = environmentDataRef.current.redmineProjectName;
      project_id = redmineSettingsRef.current.projects[project_name].id;
    }

    const issueData = {
      registrationType: registrationTypeFromURL,
      subject:
        commonSettingsDataRef.current['initialProviderRedmineSubjectPrefix'] +
        redmineIssueFields['provider_porMajorSystem'] +
        ' on ' +
        environmentDataRef.current.networkLabel,
      custom_fields: redmineIssueFields,
    };

    let rmIssue = {};

    rmIssue =
      redmineConfigsRef.current.tracker_templates.providerDiscoveryRequest.create_tracker(
        {
          redmineProjectSettings:
            redmineSettingsRef.current.projects[project_name],
          issueData: issueData,
        },
      );

    rmIssue['project_id'] = project_id;

    return rmIssue;
  };

  const startSpinnerOnSubmitButton = () => {
    const submitButton = document.getElementById('saveAndProceed');
    if (submitButton) {
      originalSubmitButtonContent = submitButton.innerHTML;
      submitButton.innerHTML =
        '<span class="spinner-border spinner-border-sm" role="status" ></span>';
      submitButton.disabled = true;
    }
  };

  const stopSpinnerOnSubmitButton = () => {
    const submitButton = document.getElementsByClassName(
      'btn btn-primary btn-md my-2 custom-btn-primary',
    )[0];

    if (submitButton) {
      submitButton.innerHTML = originalSubmitButtonContent;
      submitButton.disabled = false;
    }
  };

  const submitFormDataToRedmine = async (
    registrationFormData,
    redmineIssueFields,
  ) => {
    if (isRedmineUnreachable) {
      console.error(
        'RedmineError: Unable to make submission to Redmine either due to missing Redmine configuration data or cannot connect to Redmine',
      );

      setAlert({
        show: true,
        variant: 'danger',
        heading: 'Error',
        text: 'Unable to complete submission. Cannot connect to the Redmine server.',
        buttons: [],
      });
    } else {
      try {
        const rmIssue = createRedmineIssue(
          registrationFormData,
          redmineIssueFields,
        );

        const redmineIssueID = await postRedmineIssue(rmIssue);

        isSubmitting = false;

        navigateNextComponent({
          isInitialRegistration: true,
          redmineIssueID: redmineIssueID,
        });
      } catch (error) {
        console.error('Failed to create and POST Redmine issue: ', error);

        setAlert({
          show: true,
          variant: 'danger',
          heading: 'Error',
          text: commonSettingsDataRef.current[
            'providerInitialRegistrationSubmitError'
          ],
          buttons: [],
        });
      }
    }
  };

  const handleRender = () => {
    isMountedRef.current = true;

    const labels = document.querySelectorAll('.col-form-label');

    labels.forEach((label) => {
      if (
        label.textContent.trim() === 'What is the purpose of your visit today?'
      ) {
        label.style.fontWeight = 'bold';
      }
    });

    if (environmentDataRef.current.environment.toLowerCase().includes('prod')) {
      return;
    }
    window.formObj = {
      setSubmission: (submission) => {
        setSubmissionData({ data: submission });
      },
    };
  };

  const handleFormReady = (formInstance) => {
    formInstanceRef.current = formInstance;
  };

  const handleChange = (jsonAnswers) => {
    if (isMountedRef.current) {
      if (jsonAnswers.changed !== undefined) {
        setSubmissionData({ data: jsonAnswers.data });
      }
    }
  };

  const handleCustomEvents = async (customEvent) => {
    if (isMountedRef.current) {
      const { type } = customEvent;

      if (type === 'demographicsSave') {
        formInstanceRef.current.submit();
      }
    }
  };

  const handleFormSubmit = async (submission) => {
    // Only perform operations if Registration is still mounted

    if (isSubmitting) {
      return;
    }

    scrollToTop();
    startSpinnerOnSubmitButton();

    if (isMountedRef.current) {
      isSubmitting = true;

      // Start with filtering out all fields unrelated to this submission from formio submission data
      // This object will be used to save the form data for later use by the PCT.
      const registrationFormData = filterUnrelatedFields(
        submission.data,
        filters,
      );

      setRegistrationData(() => ({
        ...registrationData,
        [registrationTypeFromURL]: {
          ...registrationData[registrationTypeFromURL],
          data: registrationFormData,
        },
      }));

      // We need a second copy of the submission data for creating the redmine issue.
      // This object will have additional data added to it, so it needs to be mutable.
      let redmineIssueFields = filterUnrelatedFields(submission.data, filters);

      if (
        registrationTypeFromURL === 'provider' &&
        registrationFormData.provider_visitPurpose === 'firstVisit'
      ) {
        submitFormDataToRedmine(registrationFormData, redmineIssueFields);
      } else {
        navigateNextComponent({
          isInitialRegistration: false,
          redmineIssueID: null,
        });
      }

      stopSpinnerOnSubmitButton();
    }
  };

  const handleResetForm = () => {
    setRegistrationData(() => ({
      ...registrationData,
      [registrationTypeFromURL]: {},
    }));

    setInterviewData(() => ({
      ...interviewData,
      [registrationTypeFromURL]: {},
    }));

    setSubmissionData({ data: {} });

    if (registrationTypeFromURL === 'consumer') {
      modifyStateMatrix([
        { row: 0, col: 0, value: 0 },
        { row: 0, col: 1, value: 0 },
        { row: 0, col: 2, value: 0 },
      ]);
    } else {
      modifyStateMatrix([
        { row: 1, col: 0, value: 0 },
        { row: 1, col: 1, value: 0 },
        { row: 1, col: 2, value: 0 },
      ]);
    }
  };

  const handleExportToJSONFile = () => {
    exportToJSONFile({
      formData: submissionDataRef.current,
      formType: 'Registration',
      pctVersion: pctVersionRef.current,
      registrationType: registrationTypeFromURL,
      formVersion: formVersionRef.current,
      filters: filters,
    });
  };

  const handleImportFromJSONFile = async () => {
    try {
      const parsedData = await importFromJSONFile();

      if (parsedData._metadata === undefined) {
        alert(
          'File metadata field is missing Please upload a valid JSON file.',
        );
        return;
      }
      if (parsedData._metadata.registrationType !== registrationTypeFromURL) {
        alert(
          `The data you are uploading is for a ${parsedData._metadata.registrationType} registration.\n\nPlease upload a file for ${registrationTypeFromURL} registration type or switch registration types.`,
        );
        return;
      }
      if (parsedData._metadata.dataType !== 'Registration') {
        alert(
          `The data you are uploading is for the ${parsedData._metadata.dataType} form.\n\nPlease upload a file for interview form`,
        );
        return;
      }
      if (
        parsedData._metadata.formVersion !==
        formVersionRef.current.replace('Form Version: ', '')
      ) {
        alert(
          'The data you are uploading is from an older version of this form.\n\nPlease check for missing or incorrect data before submitting the form with this data.',
        );
      }

      setSubmissionData({ data: parsedData.data });
    } catch (error) {
      console.error('Error importing file:', error.message);
    }
  };

  const handleTestDataFill = async (testDataPath) => {
    setSubmissionData(await fetchJson(testDataPath));
  };

  const addBaselineFillDropdownItems = () => {
    const items = Object.entries(baselineFiles[registrationTypeFromURL]).map(
      ([label, fileName]) => (
        <Dropdown.Item
          key={label}
          data-testid={
            label
              .replace(/\s+/g, '')
              .replace(/^./, (char) => char.toLowerCase()) + 'DropDownItem'
          }
          onClick={() => {
            handleTestDataFill(fileName);
          }}
        >
          {label}
        </Dropdown.Item>
      ),
    );

    items.unshift(
      <Dropdown.ItemText key="item-text" className="text-muted">
        Test Data
      </Dropdown.ItemText>,
    );

    items.push(<Dropdown.Divider key="divider" />);

    return items;
  };

  // Cleanup items when component unmounts
  useEffect(() => {
    if (!errorLoadingForm) {
      return () => {
        isMountedRef.current = false;
        window.formObj = null;
      };
    }
  }, []);

  // Fetch and render formio form
  useEffect(() => {
    if (!errorLoadingForm) {
      setRegistrationType(registrationTypeFromURL);

      const registrationJsonPath = commonSettingsData[
        'registrationFile'
      ].replace('{{registrationType}}', registrationTypeFromURL);

      const fetchData = async () => {
        const abortController = new AbortController();
        const { signal } = abortController;

        try {
          const [form] = await Promise.all([
            fetchJson(registrationJsonPath, signal),
          ]);

          setForm(form);
          setLoading(false);
          setFormVersion('Form Version: ' + form.properties.version);
          setErrorLoadingForm(false);
        } catch (error) {
          setLoading(false);
          setErrorLoadingForm(true);
        }

        setSubmissionData({
          data: registrationData[registrationTypeFromURL].data,
        });

        return () => {
          abortController.abort();
        };
      };

      fetchData();
    }
  }, [registrationTypeFromURL, isRedmineUnreachable]);

  useEffect(() => {
    setRegistrationType(registrationTypeFromURL);
  }, []);

  useEffect(() => {
    const fetchBaselines = async () => {
      try {
        setBaselineFiles(
          await fetchJson('/common/baselines/registration-baselines.json'),
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (!environmentData.environment.toLowerCase().includes('prod')) {
      fetchBaselines();
    }
  }, []);

  useEffect(() => {
    redmineSettingsRef.current = redmineSettings;
    environmentDataRef.current = environmentData;
    pctVersionRef.current = pctVersion;
    userDNRef.current = userDN;
    redmineConfigsRef.current = redmineConfigs;
    commonSettingsDataRef.current = commonSettingsData;
    submissionDataRef.current = submissionData;
    formVersionRef.current = formVersion;
  }, [
    redmineSettings,
    environmentData,
    pctVersion,
    userDN,
    redmineConfigs,
    commonSettingsData,
    submissionData,
    formVersion,
  ]);

  // Render a loading message if the data is not loaded yet.
  if (!errorLoadingForm) {
    if (loading) return <Indicator />;
  }

  return (
    <div
      id="registration"
      data-testid="registration"
      className="main-formio-container"
    >
      {errorLoadingForm ? (
        <FormLoadError />
      ) : (
        <div
          id="registrationForm"
          data-testid="registrationForm"
          className="container-lg"
          tabIndex="0"
        >
          <RegistrationNavBar className="header-menu-container mx-0" />
          <div id="formioRegistrationForm">
            <Form
              form={form}
              formReady={handleFormReady}
              submission={submissionData}
              onChange={handleChange}
              onCustomEvent={handleCustomEvents}
              onRender={handleRender}
              onSubmit={handleFormSubmit}
            />
          </div>
          <div
            id="registrationMenu"
            data-testid="registrationOregistrationMenutions"
          >
            <Dropdown
              as={ButtonGroup}
              key="registrationMenuDropDown"
              id="registrationMenuDropDown"
              data-testid="registrationMenuDropDown"
              variant="primary"
              drop="up-centered"
            >
              <Dropdown.Toggle
                key="registrationMenuDropDownToggle"
                id="registrationMenuDropDownToggle"
                data-testid="registrationMenuDropDownToggle"
                variant="btn btn-primary custom-btn-primary"
              >
                Registration Menu
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!environmentData.environment.toLowerCase().includes('prod') ? (
                  <div>
                    {baselineFiles ? addBaselineFillDropdownItems() : null}
                  </div>
                ) : null}
                <Dropdown.Item
                  id="resetRegistrationFormDropDownItem"
                  data-testid="resetRegistrationFormDropDownItem"
                  eventKey="2"
                  onClick={() => {
                    handleShowClearFormDialog();
                  }}
                >
                  {`Reset ${registrationTypeFromURL.charAt(0).toUpperCase() + registrationTypeFromURL.slice(1)} Registration Form`}{' '}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="3"
                  id="exportToJsonDropDownItem"
                  data-testid="exportToJsonDropDownItem"
                  onClick={() => {
                    handleExportToJSONFile();
                  }}
                >
                  Export Data to JSON File
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  id="importFromJsonDropDownItem"
                  data-testid="importFromJsonDropDownItem"
                  onClick={() => {
                    handleImportFromJSONFile();
                  }}
                >
                  Import Data from JSON File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Modal
            id="resetRegistrationFormModal"
            data-testid="resetRegistrationFormModal"
            show={showResetFormDialog}
            onHide={handleCloseFormDialog}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>{alertText}</Modal.Body>
            <Modal.Footer>
              <Button
                id="cancelResetRegistrationFormBtn"
                data-testid="cancelResetRegistrationFormBtn"
                variant="secondary"
                onClick={handleCloseFormDialog}
              >
                Cancel
              </Button>
              <Button
                id="confirmResetRegistrationFormBtn"
                data-testid="confirmResetRegistrationFormBtn"
                variant="btn btn-primary custom-btn-primary"
                onClick={() => {
                  handleResetForm();
                  handleCloseFormDialog();
                }}
              >
                {`Reset ${registrationTypeFromURL.charAt(0).toUpperCase() + registrationTypeFromURL.slice(1)} Registration Form`}
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer leftHandText={formVersion} />
        </div>
      )}
    </div>
  );
};

export default Registration;
