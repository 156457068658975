export const fetchJson = async (path, signal) => {
  const whitelist = ['/common', '/configs', '/content'];

  const timestamp =
    Math.floor(new Date().getTime() / (30 * 60 * 1000)) * (30 * 60 * 1000);

  const isValidPath = whitelist.some(
    (prefix) => path === prefix || path.startsWith(prefix + '/'),
  );

  if (
    isValidPath &&
    !path.includes('..') &&
    !path.includes('://') &&
    path.startsWith('/')
  ) {
    try {
      const sanitizedPath = path + '?cb=' + timestamp;

      let response;

      if (signal !== undefined) {
        response = await fetch(sanitizedPath, { signal });
      } else {
        response = await fetch(sanitizedPath);
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    } catch (error) {
      console.error('Fetch error: ', error);
      throw new Error('Failed to fetch the JSON');
    }
  } else {
    throw new Error('Invalid or unsafe path');
  }
};
