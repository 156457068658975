import { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useLocation, useParams } from 'react-router-dom';

import DSALoadError, { RedirectDSAGenerator } from './DSALoadError.js';
import Indicator from './Indicator.js';
import PCTAlertContext from './PCTAlertContext.js';
import OnboardingStateContext from './OnboardingStateContext.js';
import OnboardingDataContext from './OnboardingDataContext.js';
import EnvironmentContext from './EnvironmentContext.js';
import CommonSettingsContext from './CommonSettingsContext.js';

import { uc1 } from '../global-utils.js';
import { saveAs } from 'file-saver';

const DSAViewer = () => {
  const location = useLocation();

  const [, setAlert] = useContext(PCTAlertContext);
  const { setRegistrationType } = useContext(OnboardingDataContext);
  const { onboardingStateMatrix, setOnboardingStateMatrix } = useContext(
    OnboardingStateContext,
  );
  const { environmentData, isEnvironmentDataLoaded } =
    useContext(EnvironmentContext);
  const { commonSettingsData, isCommonSettingsDataLoaded } = useContext(
    CommonSettingsContext,
  );

  const { registrationTypeFromURL } = useParams();

  const dsaBlob =
    location.state && location.state.dsaBlob ? location.state.dsaBlob : null;

  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [downloadButtonFileName, setDownloadButtonFileName] = useState('');
  const [dsaLoadError] = useState(
    !isEnvironmentDataLoaded || !isCommonSettingsDataLoaded || dsaBlob == null,
  );

  const [isInvalidState, setIsInvalidState] = useState(false);
  const [missingStep, setMissingStep] = useState('');
  const [missingStepRegistrationType, setMissingStepRegistrationType] =
    useState('');

  const dsaFileName =
    location.state && location.state.dsaFileName
      ? location.state.dsaFileName
      : 'dsa.pdf';
  const customer_type = uc1(registrationTypeFromURL);
  const dsaTitle = 'Data ' + customer_type;
  const altDsaLoadErrorMessage =
    dsaBlob == null ? 'Unable to load your DSA' : '';

  const modifyStateMatrix = (modifications) => {
    const updatedMatrix = [...onboardingStateMatrix];

    for (const { row, col, value } of modifications) {
      updatedMatrix[row][col] = value;
    }

    setOnboardingStateMatrix(updatedMatrix);
  };

  const checkForMissingData = () => {
    let isMissingData = false;

    if (
      (registrationTypeFromURL === 'consumer' &&
        onboardingStateMatrix[0][0] === 0) ||
      (registrationTypeFromURL === 'provider' &&
        onboardingStateMatrix[1][0] === 0)
    ) {
      setIsInvalidState(true);
      isMissingData = true;
      setMissingStep('registration');
      setMissingStepRegistrationType(registrationTypeFromURL);
    } else if (
      (registrationTypeFromURL === 'consumer' &&
        onboardingStateMatrix[0][0] === 1 &&
        onboardingStateMatrix[0][1] !== 1) ||
      (registrationTypeFromURL === 'provider' &&
        onboardingStateMatrix[1][0] === 1 &&
        onboardingStateMatrix[1][1] !== 1)
    ) {
      setIsInvalidState(true);
      isMissingData = true;
      setMissingStep('interview');
      setMissingStepRegistrationType(registrationTypeFromURL);
    }

    return isMissingData;
  };

  useEffect(() => {
    if (!checkForMissingData() && !dsaLoadError) {
      setUrl(URL.createObjectURL(dsaBlob));
      setLoading(false);
      saveAs(dsaBlob, dsaFileName);
      setDownloadButtonFileName(dsaFileName);

      setAlert({
        show: true,
        variant: 'success',
        heading: 'DSA Downloaded',
        text: commonSettingsData.dsaAutoDownloaded,
        buttons: [],
      });
    }
  }, []);

  useEffect(() => {
    setRegistrationType(registrationTypeFromURL);
  }, []);

  useEffect(() => {
    return () => {
      // Reset the state of the Interview before unmounting this component. This is so users cannot return
      // to a prior DSA screen without resubmitting the interview. We no longer maintain the DSAs outside
      // of the scope of the DSAViewer.
      if (registrationTypeFromURL === 'consumer') {
        modifyStateMatrix([{ row: 0, col: 1, value: 0 }]);
      } else {
        modifyStateMatrix([{ row: 1, col: 1, value: 0 }]);
      }
    };
  }, []);

  const handleDownload = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      if (response.ok) {
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = downloadButtonFileName;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } else {
        throw new Error('Error downloading the DSA file');
      }
    } catch (error) {
      console.error('Error downloading the DSA file:', error);

      setAlert({
        show: true,
        variant: 'warning',
        heading: 'Unable to download DSA to your Computer',
        text: `Please refresh the page. If problem persists contact the CDF help desk ${environmentData.pctHelpDeskEmail}`,
        buttons: [],
      });
    }
  };

  if (!dsaLoadError && !isInvalidState) {
    if (loading) return <Indicator />;
  }

  return (
    <div id="dsaGenerator" data-testid="dsaGenerator" tabIndex="0">
      {isInvalidState ? (
        <RedirectDSAGenerator
          missingStep={missingStep}
          missingStepRegistrationType={missingStepRegistrationType}
        />
      ) : dsaLoadError ? (
        <>
          <DSALoadError
            downloadFunction={handleDownload}
            altMessage={altDsaLoadErrorMessage}
          />
        </>
      ) : (
        <>
          <div id="dsaRegistrationType" className="header">
            <span className="dsa-generator-registration-type">
              {dsaTitle} on {environmentData['networkLabel']}
              <span className="additional-text">
                &nbsp; - Data Sharing Agreement &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <Button
              variant="btn btn-primary custom-btn-primary"
              id="downloadDSABtn"
              data-testid="downloadDSABtn"
              onClick={handleDownload}
            >
              Download DSA
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
            <div id="dsaViewer" style={{ flex: 1 }}>
              <iframe
                src={url}
                width="100%"
                height="100%"
                id="dsaVieweriFrame"
                data-testid="dsaVieweriFrame"
                className="pdf-iframe"
                title={`${registrationTypeFromURL.charAt(0).toUpperCase() + registrationTypeFromURL.slice(1)} DSA`}
              ></iframe>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DSAViewer;
