import React, { useState } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import HTMLDisplay from './HTMLDisplay';
import { useEffect } from 'react';
import Indicator from './Indicator';

const Learn = () => {
  const [data, setData] = useState([]);
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [, setSelectedFilename] = useState(null);
  const path = '/content/learn-content/learnData.json';
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(path);

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        const jsonData = await response.json();

        setData(jsonData.learnPages);
        setActiveTab(jsonData.learnPages[0].title);
        fetchHtmlContent(jsonData.learnPages[0].filename);
        setLoading(false);
      } catch (error) {
        console.error('Unable to get lean page data file', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchHtmlContent = async (filename) => {
    if (filename.endsWith('.js')) {
      setSelectedFilename(filename);
      setHtmlContent(null);
    } else {
      try {
        const htmlResponse = await fetch(`/content/learn-content/${filename}`);

        if (!htmlResponse.ok) {
          throw new Error(`HTTP error: ${htmlResponse.status}`);
        }

        const htmlData = await htmlResponse.text();

        setHtmlContent(htmlData);
        setSelectedFilename(null);
      } catch (error) {
        console.error('Unable to get html file', error);
      }
    }
  };

  const toggle = (filename) => {
    setActiveTab(filename);
    fetchHtmlContent(filename);
  };

  if (loading) return <Indicator />;
  return (
    <div id="pctLearn">
      <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
        <Row>
          <Col lg={3}>
            <Nav
              id="verticalNavLearn"
              className="flex-column tab-style"
              key="row 1"
            >
              {data &&
                data.map((item) => (
                  <Nav.Item key={item.id}>
                    <Nav.Link
                      eventKey={item.title}
                      className={activeTab === item.title ? 'active' : ''}
                      href="#"
                      onClick={() => toggle(item.filename)}
                      role="button"
                    >
                      {item.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
          </Col>
          <Col lg={9}>
            <Tab.Content active={activeTab}>
              <HTMLDisplay html={htmlContent} />
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default Learn;
