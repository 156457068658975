import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import EnvironmentContext from './EnvironmentContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export const RedirectInterview = (props) => {
  const lower_caseRegistrationType =
    props.selectedInterviewPath.charAt(0).toLowerCase() +
    props.selectedInterviewPath.slice(1);
  const navigate = useNavigate();

  const goToRegistration = () => {
    navigate(`/onboard/${props.interviewPath}/registration`);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <div
        id="interviewUnavailableError"
        data-testid="interviewUnavailableError"
        className="error-message-box"
        style={{
          backgroundColor: '#f8d7da',
          padding: '20px 20px',
          borderRadius: '10px',
        }}
      >
        <h2>{props.selectedInterviewPath} Interview Unavailable</h2>
        <p style={{ fontSize: '20px' }}>
          Please submit your
          <b> {lower_caseRegistrationType} registration </b> form to proceed
          with the interview. <br />
        </p>
        <div>
          <Button onClick={goToRegistration}>
            Return to {props.selectedInterviewPath} Registration Form
          </Button>
        </div>
      </div>
    </div>
  );
};

const FormLoadError = () => {
  const { environmentData, isEnvironmentDataLoaded } =
    useContext(EnvironmentContext);

  if (isEnvironmentDataLoaded) {
    return (
      <div
        id="formLoadError"
        data-testid="formLoadError"
        style={{ textAlign: 'center', marginTop: '50px' }}
      >
        <div
          id="formLoadErrorMessageBox"
          data-testid="formLoadErrorMessageBox"
          className="error-message-box"
          style={{
            backgroundColor: '#f8d7da',
            padding: '20px 20px',
            borderRadius: '10px',
          }}
        >
          <h1>Form Failed to Load</h1>
          <p>
            Requested form failed to load. Check your internet connection and
            reload.
            <br />
            If problem persists contact CDF Help Desk at&nbsp;
            <a href={`mailto:${environmentData.pctHelpDeskEmail}`}>
              {environmentData.pctHelpDeskEmail}
            </a>
            .
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        data-testid="FormLoadError"
        style={{ textAlign: 'center', marginTop: '50px' }}
      >
        <div
          id="formLoadErrorMessageBox"
          data-testid="formLoadErrorMessageBox"
          className="error-message-box"
          style={{
            backgroundColor: '#f8d7da',
            padding: '20px 20px',
            borderRadius: '10px',
          }}
        >
          <h1>Form Cannot be Loaded</h1>
          <p>
            Requested form failed to load because the environment data is
            missing.
          </p>
        </div>
      </div>
    );
  }
};

RedirectInterview.propTypes = {
  interviewPath: PropTypes.string.isRequired,
  selectedInterviewPath: PropTypes.string.isRequired,
};

export default FormLoadError;
