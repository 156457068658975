import { useContext } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import EnvironmentContext from './EnvironmentContext';

const NavBar = () => {
  let navLocation = useLocation();

  const { isEnvironmentDataLoaded, environmentData } =
    useContext(EnvironmentContext);

  const renderEnvironmentDefinedLinks = () => {
    return Object.keys(environmentData.links).map((key) => (
      <Nav.Link
        id={`navBar${key.replace(/\s+/g, '')}Link`}
        href={environmentData.links[key]}
        key={key}
        target="_blank"
        active={false}
      >
        {key}
      </Nav.Link>
    ));
  };

  const addLinkToLearn = () => {
    return (
      <Nav.Link
        id="navBarLearnLink"
        as={Link}
        to="/learn"
        active={navLocation.pathname === '/learn'}
      >
        About CDF
      </Nav.Link>
    );
  };

  return (
    <div id="navBar" className="px-0">
      <Navbar
        id="topNavBar"
        variant="dark"
        className="align-items-end justify-content-between py-0 px-0"
      >
        <div className="top-0 end-0">
          <Nav>
            <Nav.Link
              id="navBarHomeLink"
              as={Link}
              to="/home"
              active={
                navLocation.pathname === '/' || navLocation.pathname === '/home'
              }
            >
              Home
            </Nav.Link>
            <Nav.Link
              id="navBarOnboardLink"
              as={Link}
              to="/onboard"
              active={
                navLocation.pathname === '/onboard' ||
                navLocation.pathname === '/onboard/consumer/previewinterview' ||
                navLocation.pathname === '/onboard/provider/previewinterview' ||
                navLocation.pathname === '/onboard/provider/thankyou' ||
                navLocation.pathname === '/onboard/consumer/thankyou' ||
                navLocation.pathname === '/onboard/consumer/registration' ||
                navLocation.pathname === '/onboard/provider/registration' ||
                navLocation.pathname === '/onboard/consumer/interview' ||
                navLocation.pathname === '/onboard/provider/interview' ||
                navLocation.pathname === '/onboard/consumer/dsa' ||
                navLocation.pathname === '/onboard/provider/dsa'
              }
            >
              Onboard
            </Nav.Link>
            {isEnvironmentDataLoaded && environmentData.links?.['About CDF']
              ? null
              : addLinkToLearn()}
            {isEnvironmentDataLoaded && environmentData.links
              ? renderEnvironmentDefinedLinks()
              : null}
          </Nav>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
