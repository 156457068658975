export const dlInterviewGeneratorFunctions = {
  _isPageBreakNeeded({
    text,
    y,
    options: { width: width = undefined, alignment: alignment },
  }) {
    let heightOfText = 0;

    if (width != undefined) {
      heightOfText = Math.ceil(
        y +
          this.document.heightOfString(text, {
            width: width,
            align: alignment,
          }),
      );
    } else {
      heightOfText = Math.ceil(
        y +
          this.document.heightOfString(text, {
            align: alignment,
          }),
      );
    }

    const heightOfContentArea = Math.ceil(
      this.document.page.height - this.document.page.margins.bottom,
    );

    return heightOfText >= heightOfContentArea - 5; // Add a 5 point buffer just to catch edge cases
  },

  _checkQAndEForBreak(question, explanation) {
    /* Get the estimated height of question and explanation. To do so temporarily set the font and fontSize.
    This is done so that the heightOfString returns actually estimated height of the text to be written.
    These will be set again by this._addText before text is written to page. */
    if (question.font != 'default') {
      this.document.font(question.font);
    }

    this.document.fontSize(question.fontSize);
    const titleHeight = this.document.heightOfString(question.text, {
      align: question.alignment,
    });

    if (explanation.font != 'default') {
      this.document.font(explanation.font);
    }

    this.document.fontSize(explanation.fontSize);
    const bodyHeight = this.document.heightOfString(explanation.text, {
      align: explanation.alignment,
    });

    const heightText = Math.ceil(this.document.y + titleHeight + bodyHeight);
    const heightContentArea = Math.ceil(
      this.document.page.height - this.document.page.margins.bottom,
    );

    return heightText >= heightContentArea - 5; // Add a 5 point buffer just to catch edge cases
  },

  _addPageBreak() {
    const marks = this.document.page.markings; // Carry markings over to new page.
    this.document.addPage({ size: 'LETTER' });
    this.document.page.markings = marks;
  },

  _addText({
    text,
    font,
    fontSize,
    fillColor,
    alignment,
    moveDown,
    x = this.document.x,
  }) {
    if (font !== 'default') {
      this.document.font(font);
    }

    this.document.fontSize(fontSize);
    this.document.fillColor(fillColor);

    // Don't let text blocks break across pages. Add a new page and then the text if risk of breaking across page.
    if (
      this._isPageBreakNeeded({
        text: text,
        y: this.document.y,
        options: { alignment: alignment },
      })
    ) {
      this._addPageBreak();
    }

    this.document.text(text, x, this.document.y, { align: alignment });

    this.document.moveDown(moveDown);
  },

  _extract_questions(section, indent, textSettings) {
    indent = indent || 0;

    const startX = this.document.x;
    section.questions.forEach((q) => {
      const question = {
        text: q.question,
        font:
          this.fonts !== null && this.fonts !== undefined
            ? textSettings.questionFont
            : 'default',
        fontSize: textSettings.questionFontSize,
        fillColor: textSettings.fillColor,
        alignment: textSettings.alignment,
        moveDown: textSettings.moveDown,
        x: startX + indent,
      };

      const explanation = {
        text: q.explanation,
        font:
          this.fonts !== null && this.fonts !== undefined
            ? textSettings.explanationFont
            : 'default',
        fontSize: textSettings.fontSize,
        fillColor: textSettings.fillColor,
        alignment: textSettings.alignment,
        moveDown: textSettings.explanationMoveDown,
        x: startX + indent,
      };

      // We don't want to break up question and explanation with a page break, check if one will be needed and preemptively create the break
      if (this._checkQAndEForBreak(question, explanation)) {
        this._addPageBreak();
      }

      this._addText({
        text: question.text,
        font:
          this.fonts !== null && this.fonts !== undefined
            ? question.font
            : 'default',
        fontSize: question.fontSize,
        fillColor: question.fillColor,
        alignment: question.alignment,
        moveDown: question.moveDown,
        x: question.x,
      });

      this._addText({
        text: explanation.text,
        font:
          this.fonts !== null && this.fonts !== undefined
            ? explanation.font
            : 'default',
        fontSize: explanation.fontSize,
        fillColor: explanation.fillColor,
        alignment: explanation.alignment,
        moveDown: explanation.moveDown,
        x: explanation.x,
      });

      const options = [
        { key: 'yes', text: 'A: Yes' },
        { key: 'no', text: 'A: No' },
        { key: 'high', text: 'A: High' },
        { key: 'moderate', text: 'A: Moderate' },
        { key: 'low', text: 'A: Low' },
      ];

      for (const answer in q.answers) {
        if (q.answers[answer].length > 0) {
          /* We don't want to have the printed option text on a page separate than the question and explanations that follow it.
              The problem is that since this is a recursive call we don't know the size of the question and explanation text to
              know if there will be a page break. The next best thing is to be conservative and break in advance if current document
              y value is within 150 points fom the top of the bottom margin.
            */
          if (
            this.document.y >=
            this.document.page.height - this.document.page.margins.bottom - 150
          ) {
            this._addPageBreak();
          }

          this._addText({
            text: 'A: ' + answer.charAt(0).toUpperCase() + answer.slice(1),
            font:
              this.fonts !== null && this.fonts !== undefined
                ? textSettings.answerFont
                : 'default',
            fontSize: textSettings.fontSize,
            fillColor: textSettings.answerFillColor,
            alignment: textSettings.alignment,
            moveDown: textSettings.moveDown,
            x: startX + indent + 12,
          });

          const xBeforeRecurse = startX;
          this._extract_questions(
            { questions: q.answers[answer] },
            12,
            textSettings,
          );
          this.document.x = xBeforeRecurse;
        }
      }
    });
  },

  _setSections() {
    if (this.template.sections) {
      for (const section of this.template.sections) {
        if (
          this.document.y >=
          this.document.page.height - this.document.page.margins.bottom - 150
        ) {
          this._addPageBreak();
        }

        this._addText({
          text: section.title.text,
          font:
            this.fonts !== null && this.fonts !== undefined
              ? section.title.font
              : 'default',
          fontSize: section.title.fontSize,
          fillColor: section.title.fillColor,
          alignment: section.title.alignment,
          moveDown: section.title.moveDown,
        });

        this._extract_questions(section, 0, section.body.textSettings);
      }
    }
  },

  _addClassification() {
    const pageRange = this.document.bufferedPageRange();

    for (let i = 0; i < pageRange.count; i++) {
      this.document.switchToPage(i);

      // Header
      this.document.y = 20;
      this.document.font('Helvetica-Bold').text(`${this.classification}`, {
        align: 'center',
        width: 450,
        height: 20,
      }); // Don't use _addText here.

      // Footer
      this.document.y = 760;
      this.document.font('Helvetica-Bold').text(`${this.classification}`, {
        align: 'center',
        width: 450,
        height: 760,
      }); // Don't use _addText here.
    }
  },

  _addPageNumbers() {
    const pageRange = this.document.bufferedPageRange();

    // Always use document default font for page numbers.
    if (this.fonts != null) {
      this.document.font(this.template.documentSettings.defaultFont);
    }

    for (let i = 0; i < pageRange.count; i++) {
      this.document.switchToPage(i);

      this.document.y = 760;

      this.document.text(`${i + 1} of ${pageRange.count}`, {
        align: 'right',
        width: 500,
        height: 760,
      }); // Don't use _addText here.
    }
  },

  getFileName() {
    return this.template.documentSettings.fileName;
  },

  generateDLInterview() {
    if (this.fonts != null) {
      for (const font of this.fonts) {
        this.document.registerFont(font.name, font.buffer);
      }
    }

    // DSA Title
    this.document.markContent('H1'); // Mark content sections to improve accessibility

    this._addText({
      text: this.template.title.text,
      font:
        this.fonts !== null && this.fonts !== undefined
          ? this.template.title.font
          : 'default',
      fontSize: this.template.title.fontSize,
      fillColor: this.template.title.fillColor,
      alignment: this.template.title.alignment,
      moveDown: this.template.title.moveDown,
    });

    this.document.endMarkedContent(); // "H1"

    this._setSections();

    if (this.classification != '') {
      this._addClassification();
    }

    this._addPageNumbers();

    this.document.end();
  },
};
