import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getPolicyStr } from '../policy-utils.js';
import OnboardingDataContext from './OnboardingDataContext';
import CommonSettingsContext from './CommonSettingsContext';

// Function to compute differences between two arrays
function computeDiff(currentArray, calculatedArray) {
  const added = calculatedArray.filter((item) => !currentArray.includes(item));
  const removed = currentArray.filter(
    (item) => !calculatedArray.includes(item),
  );
  const unchanged = currentArray.filter((item) =>
    calculatedArray.includes(item),
  );

  return { added, removed, unchanged };
}

const PoliciesAttachedViewer = (props) => {
  const { registrationType } = useContext(OnboardingDataContext);
  const { commonSettingsData } = useContext(CommonSettingsContext);

  const [currentAttachedPoliciesArray, setCurrentAttachedPoliciesArray] =
    useState([]);
  const [diff, setDiff] = useState({
    added: [],
    removed: [],
    unchanged: [],
  });

  useEffect(() => {
    if (props.submission.data) {
      const calculatedAttachedPoliciesArray = getPolicyStr(
        props.policyList,
        props.submission.data,
        registrationType,
        commonSettingsData.policyFragmentPrefixConsumer,
        commonSettingsData.policyFragmentPrefixProvider,
        commonSettingsData.policyFragmentSuffix,
      )
        .replace(/^-/gm, '')
        .split('\n')
        .filter((line) => line.trim() !== '');

      // Compute the differences between the two arrays
      const diffResult = computeDiff(
        currentAttachedPoliciesArray,
        calculatedAttachedPoliciesArray,
      );
      setDiff(diffResult);

      setCurrentAttachedPoliciesArray(calculatedAttachedPoliciesArray);
    }
  }, [props.submission]);

  return (
    <div
      id="policiesAttachedViewer"
      data-testid="policiesAttachedViewer"
      className="col-lg-3"
    >
      <p style={{ fontWeight: 'bold', color: 'GrayText' }}>Policies Attached</p>
      <div className="current-document">
        <ul>
          {diff.added.map((line, index) => (
            <li key={index} style={{ color: 'green' }}>
              {line}
            </li>
          ))}
          {diff.removed.map((line, index) => (
            <li key={index} style={{ color: 'red' }}>
              {line}
            </li>
          ))}
          {diff.unchanged.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      </div>
      <hr />
      <p style={{ color: 'graytext', fontSize: 'smaller' }}>
        **Caveat
        <br />
        The &quot;Policies Attached&quot; list highlights ADDED policies (green)
        or REMOVED policies (red) based on selected answers. The highlighted
        change will sometimes appear split across multiple lines, but notice
        that the entire highlighted text does accurately comprise the full
        policy string that was added or removed.
      </p>
    </div>
  );
};

PoliciesAttachedViewer.propTypes = {
  policyList: PropTypes.object.isRequired,
  submission: PropTypes.object.isRequired,
};

export default PoliciesAttachedViewer;
