import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const OnboardingDataContext = createContext();

export const OnboardingDataProvider = ({ children }) => {
  const registrationDataKey = 'currentRegistrationData';
  const interviewDataKey = 'currentInterviewData';
  const policiesEnforcedKey = 'policiesEnforced';
  const registrationTypeKey = 'registrationType';

  const registrationDataFromStorage = JSON.parse(
    localStorage.getItem(registrationDataKey),
  );
  const interviewDataFromStorage = JSON.parse(
    localStorage.getItem(interviewDataKey),
  );
  const policiesEnforcedFromStorage = JSON.parse(
    localStorage.getItem(policiesEnforcedKey),
  );
  const registrationTypeFromStorage = JSON.parse(
    localStorage.getItem(registrationTypeKey),
  );

  const [registrationData, setRegistrationData] = useState(
    registrationDataFromStorage || { consumer: {}, provider: {} },
  );

  const [interviewData, setInterviewData] = useState(
    interviewDataFromStorage || { consumer: {}, provider: {} },
  );

  const [policiesEnforced, setPoliciesEnforced] = useState(
    policiesEnforcedFromStorage || { consumer: '', provider: '' },
  );
  const [registrationType, setRegistrationType] = useState(
    registrationTypeFromStorage || 'consumer',
  );

  useEffect(() => {
    localStorage.setItem(registrationDataKey, JSON.stringify(registrationData));
  }, [registrationData]);

  useEffect(() => {
    localStorage.setItem(interviewDataKey, JSON.stringify(interviewData));
  }, [interviewData]);

  useEffect(() => {
    localStorage.setItem(policiesEnforcedKey, JSON.stringify(policiesEnforced));
  }, [policiesEnforced]);

  useEffect(() => {
    localStorage.setItem(registrationTypeKey, JSON.stringify(registrationType));
  }, [registrationType]);

  return (
    <OnboardingDataContext.Provider
      value={{
        registrationData,
        setRegistrationData,
        interviewData,
        setInterviewData,
        policiesEnforced,
        setPoliciesEnforced,
        registrationType,
        setRegistrationType,
      }}
    >
      {children}
    </OnboardingDataContext.Provider>
  );
};

OnboardingDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OnboardingDataContext;
