import React from 'react';
import PropTypes from 'prop-types';

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="left-text">{props.leftHandText}</div>
    </footer>
  );
};

Footer.propTypes = { leftHandText: PropTypes.node };

export default Footer;
