import blobStream from 'blob-stream-browserify';
import PDFDocument from 'pdfkit/js/pdfkit.standalone.js';
import { dsaGeneratorFunctions } from './dsa-generator.js';
import { dlInterviewGeneratorFunctions } from './dl-interview-generator.js';
import {
  compareClassifications,
  findClassificationTypeByStringValue,
} from './classification.js';

export { ClassificationTypes } from './classification.js';

export const GeneratorTypes = {
  DSA: 'dsa',
  DL_INTERVIEW: 'dl_interview',
};

/*
 * Factory function that creates and returns a new pdfGenerator object.

 * Params:
 *  
 *    - generator_type: See GeneratorTypes object for available values.
 *    - template: Object that defines the template to be used to create the PDF. Must match the generator type.
 *    - data: Object containing the data that will be use to fill in the PDF template. If there are no data fields in the template use the default value.
 *    - fonts: An array of font objects. Each unique font listed in the template must have a 
 *      corresponding font object. If null (default), then the default PDFKit font will be used. Font objects must have the following fields:
 *          - name: The name of the font as it appears in the template. 
 *          - buffer: A buffer object of the font. 
 *
 */
export function createPDFGenerator({
  generator_type,
  template,
  data = {},
  fonts = null,
  dataClassificationType = null,
  releasableTo = '',
  pctVersion = '',
  policyMatrixVersion = '',
  requestId = '',
  title = '',
}) {
  const newGenerator =
    generator_type === GeneratorTypes.DSA
      ? Object.create(dsaGeneratorFunctions)
      : generator_type === GeneratorTypes.DL_INTERVIEW
        ? Object.create(dlInterviewGeneratorFunctions)
        : null;

  const newDocument = new PDFDocument({
    size: template.documentSettings.pageSize,
    margin: template.documentSettings.margin,
    pdfVersion: '1.7',
    displayTitle: true,
    bufferPages: true,
  });

  newDocument.info.Title = title;
  newDocument.info.Creator = template.documentSettings.metadata.creator;
  newDocument.info.Producer = 'PDF Generator';
  newDocument.info.PCTVersion = pctVersion;
  newDocument.info.TemplateVersion = template.documentSettings.metadata.version;
  newDocument.info.PolicyMatrixVersion = policyMatrixVersion;

  if (generator_type === GeneratorTypes.DSA) {
    newDocument.info.RequestID = requestId;
  }

  // Document must be piped to a writable blob stream so that it can be worked with from within the browser.
  const newDocumentStream = newDocument.pipe(blobStream());

  newGenerator.document = newDocument;
  newGenerator.documentStream = newDocumentStream;
  newGenerator.template = template;
  newGenerator.data = data;
  newGenerator.fonts = fonts;

  const templateClassificationString = template.documentSettings.classification;

  // Compare classification level of what was passed as parameter and what template is set to
  // The higher of the two levels becomes the overall classification level of the document.
  // When they match, always use the value in classificationString, because it may have
  // releasibility statements.
  if (templateClassificationString !== undefined) {
    // Only need to compare when classification is set in template. Not a required field
    const templateClassificationType = findClassificationTypeByStringValue(
      templateClassificationString,
    );

    if (templateClassificationType === null) {
      throw new Error(
        'Classification in template is not a known classification',
      );
    }

    if (dataClassificationType !== null) {
      if (
        compareClassifications(
          templateClassificationType,
          dataClassificationType,
        ) == 1
      ) {
        newGenerator.classification = templateClassificationString;
      } else {
        if (releasableTo != '') {
          newGenerator.classification =
            dataClassificationType.stringValue + '//' + releasableTo;
        } else {
          newGenerator.classification = dataClassificationType.stringValue;
        }
      }
    } else {
      newGenerator.classification = templateClassificationType.stringValue;
    }
  } else {
    if (dataClassificationType != null) {
      if (releasableTo != '') {
        newGenerator.classification =
          dataClassificationType.stringValue + '//' + releasableTo;
      } else {
        newGenerator.classification = dataClassificationType.stringValue;
      }
    } else {
      newGenerator.classification = '';
    }
  }

  return newGenerator;
}
