import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CommonSettingsContext = createContext();

export const CommonSettingsProvider = ({ children }) => {
  // const [commonSettingsData] = useState(window.PCT_COMMON_SETTINGS);
  const [commonSettingsData, setCommonSettingsData] = useState({});
  const [isQueryingCommonSettings, setIsQueryingCommonSetting] = useState(true);
  const [isCommonSettingsDataLoaded, setIsCommonSettingsDataLoaded] =
    useState(false);

  useEffect(() => {
    const fetchCommonSettings = async () => {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');

      const options = {
        retries: 3,
        delay: 1000,
      };

      for (let i = 0; i < options.retries; i++) {
        try {
          const response = await fetch('/common/common-settings.json');
          if (!response.ok) {
            throw new Error(
              `Response failed with status of, ${response.status}`,
            );
          } else {
            const jsonData = await response.json();
            setCommonSettingsData(jsonData);
            setIsCommonSettingsDataLoaded(true);
            setIsQueryingCommonSetting(false);
            break;
          }
        } catch (error) {
          console.error(`Fetch ${error.message} , retrying...`);
          if (i === options.retries - 1) {
            console.error('MissingData: Common data could not be loaded.');
            setIsQueryingCommonSetting(false);
            setIsCommonSettingsDataLoaded(false);
          }
        }
        await new Promise((resolve) => setTimeout(resolve, options.delay));
      }
    };

    fetchCommonSettings();
  }, []);

  return (
    <CommonSettingsContext.Provider
      value={{
        commonSettingsData,
        isQueryingCommonSettings,
        isCommonSettingsDataLoaded,
      }}
    >
      {children}
    </CommonSettingsContext.Provider>
  );
};

CommonSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CommonSettingsContext;
