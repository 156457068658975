import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import EnvironmentContext from './EnvironmentContext';

export const RedirectDSAGenerator = ({
  missingStep,
  missingStepRegistrationType,
}) => {
  const navigate = useNavigate();

  const goToMissingSection = () => {
    navigate(`/onboard/${missingStepRegistrationType}/${missingStep}`);
  };

  return (
    <div
      id="redirectDSAGenerator"
      data-testid="redirectDSAGenerator"
      style={{ textAlign: 'center', marginTop: '50px' }}
    >
      <div
        id="dsageneratorErrorMessageBox"
        data-testid="dsageneratorErrorMessageBox"
        className="error-message-box"
        style={{
          backgroundColor: '#f8d7da',
          padding: '20px 20px',
          borderRadius: '10px',
        }}
      >
        <h2>DSA is Unavailable</h2>
        <p style={{ fontSize: '20px', whiteSpace: 'nowrap' }}>
          Please submit your{' '}
          <b data-testid={`${missingStepRegistrationType}${missingStep}text`}>
            {missingStepRegistrationType.charAt(0) +
              missingStepRegistrationType.slice(1)}{' '}
            {missingStep.charAt(0) + missingStep.slice(1)}
          </b>{' '}
          form to proceed with DSA generation.
        </p>
        <div>
          <Button
            data-testid={`${missingStepRegistrationType}${missingStep}button`}
            onClick={goToMissingSection}
          >
            Return to{' '}
            {missingStepRegistrationType.charAt(0).toUpperCase() +
              missingStepRegistrationType.slice(1)}{' '}
            {missingStep.charAt(0).toUpperCase() + missingStep.slice(1)} Form
          </Button>
        </div>
      </div>
    </div>
  );
};

const DSALoadError = ({ downloadFunction, altMessage }) => {
  const { environmentData, isEnvironmentDataLoaded } =
    useContext(EnvironmentContext);

  if (!isEnvironmentDataLoaded) {
    return (
      <div
        id="dsaLoadError"
        data-testid="dsaLoadError"
        style={{ textAlign: 'center', marginTop: '50px' }}
      >
        <div
          id="dsageneratorErrorMessageBox"
          data-testid="dsageneratorErrorMessageBox"
          className="error-message-box"
          style={{
            backgroundColor: '#f8d7da',
            padding: '20px 20px',
            borderRadius: '10px',
          }}
        >
          <h1>DSA Cannot be Generated</h1>
          <p>
            DSA cannot be generated because the environment data is missing.
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        id="dsaLoadError"
        data-testid="dsaLoadError"
        style={{ textAlign: 'center', marginTop: '50px' }}
      >
        <div
          id="dsageneratorErrorMessageBox"
          data-testid="dsageneratorErrorMessageBox"
          className="error-message-box"
          style={{
            backgroundColor: '#f8d7da',
            padding: '20px 20px',
            borderRadius: '10px',
          }}
        >
          <h1>DSA Failed to Load</h1>
          <br />
          <p>
            {altMessage
              ? altMessage
              : 'Requested DSA failed to load. Click download button below to manually download the DSA.'}
            <br />
            If problem persists DSA contact CDF Help Desk at&nbsp;
            <a href={`mailto:${environmentData.pctHelpDeskEmail}`}>
              {environmentData.pctHelpDeskEmail}
            </a>
            <br />
            <br />
          </p>
          {altMessage ? (
            ''
          ) : (
            <div>
              <Button
                data-testid="dsaLoadErrorDownloadBtn"
                onClick={downloadFunction}
              >
                Download DSA
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
};

DSALoadError.propTypes = {
  downloadFunction: PropTypes.func.isRequired,
  altMessage: PropTypes.string,
};

RedirectDSAGenerator.propTypes = {
  missingStep: PropTypes.string.isRequired,
  missingStepRegistrationType: PropTypes.string.isRequired,
};

export default DSALoadError;
