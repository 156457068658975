import {
  createPDFGenerator,
  GeneratorTypes,
  ClassificationTypes,
} from 'pdf-generator';
import { fetchJson } from './fetch-json';

class PDFGenerator {
  constructor({
    environmentData,
    registrationType,
    pctVersion,
    policyMatrixVersion,
    configsPath,
  }) {
    this.environmentData = environmentData;
    this.registrationType = registrationType;
    this.pctVersion = pctVersion;
    this.policyMatrixVersion = policyMatrixVersion;
    this.configsPath = configsPath;

    this.interviewFiles = {
      consumer: `/${configsPath}/interview_previews/dl-interview-consumer.json`,
      provider: `/${configsPath}/interview_previews/dl-interview-provider.json`,
    };
  }

  async #fetchTemplateAndFonts({ pdfTemplatePath }) {
    let bufferedFonts = [];
    let pdfTemplate = {};
    let fontNames = [];

    try {
      pdfTemplate = await fetchJson(pdfTemplatePath);
      fontNames = pdfTemplate.documentSettings.fonts;

      const fontPromises = fontNames.map((fontName) =>
        fetch(`/fonts/${fontName}.ttf`),
      );

      const fontResponses = await Promise.all(fontPromises);

      bufferedFonts = await Promise.all(
        fontResponses.map((response) => response.arrayBuffer()),
      );
    } catch (error) {
      throw new Error('Error fetching PDF templates and fonts.');
    }

    let fonts = [];

    for (let i = 0; i < bufferedFonts.length; i++) {
      fonts.push({ name: fontNames[i], buffer: bufferedFonts[i] });
    }

    return [pdfTemplate, fonts];
  }

  #getDSATemplatePath(data) {
    let dsaTemplatePath = `/${this.configsPath}`;
    if (this.environmentData['hasOrganizationSpecificDSATemplates']) {
      if (
        this.environmentData['organizationSpecificDSATemplates'][
          data[`${this.registrationType}_organization`]
        ] !== undefined
      ) {
        dsaTemplatePath =
          dsaTemplatePath +
          this.environmentData['organizationSpecificDSATemplates'][
            data[`${this.registrationType}_organization`]
          ].replace('{{registrationType}}', this.registrationType);
      } else {
        dsaTemplatePath =
          dsaTemplatePath +
          this.environmentData['standardDSATemplate'].replace(
            '{{registrationType}}',
            this.registrationType,
          );
      }
    } else {
      dsaTemplatePath =
        dsaTemplatePath +
        this.environmentData['standardDSATemplate'].replace(
          '{{registrationType}}',
          this.registrationType,
        );
    }

    return dsaTemplatePath;
  }

  async #generate({ type, templatePath, data, isSampleDSA }) {
    const [template, fonts] = await this.#fetchTemplateAndFonts({
      pdfTemplatePath: templatePath,
    });

    let pdfGenerator = {};

    if (this.environmentData.addClassificationMarkingsToDocuments) {
      pdfGenerator = createPDFGenerator({
        generator_type:
          type === 'dsa' ? GeneratorTypes.DSA : GeneratorTypes.DL_INTERVIEW,
        template: template,
        data: data,
        fonts: fonts,
        dataClassificationType: ClassificationTypes.UNCLASSIFIED,
        policyMatrixVersion:
          type === 'dsa'
            ? this.policyMatrixVersion
            : template.documentSettings.metadata.version,
        pctVersion: this.pctVersion,
        requestId: data?.redmineRequestId,
        title:
          type === 'dsa'
            ? data[`${this.registrationType}_systemName`] +
              ' - ' +
              data[`${this.registrationType}_porMajorSystem`]
            : template.title.text,
      });
    } else {
      pdfGenerator = createPDFGenerator({
        generator_type:
          type === 'dsa' ? GeneratorTypes.DSA : GeneratorTypes.DL_INTERVIEW,
        template: template,
        data: data,
        fonts: fonts,
        policyMatrixVersion:
          type === 'dsa'
            ? this.policyMatrixVersion
            : template.documentSettings.metadata.version,
        pctVersion: this.pctVersion,
        requestId: data?.redmineRequestId,
        title:
          type === 'dsa'
            ? data[`${this.registrationType}_systemName`] +
              ' - ' +
              data[`${this.registrationType}_porMajorSystem`]
            : template.title.text,
      });
    }

    if (type === 'dsa') {
      pdfGenerator.generateDSA(isSampleDSA);
    } else {
      pdfGenerator.generateDLInterview();
    }

    return new Promise((resolve, reject) => {
      pdfGenerator.documentStream.on('finish', () => {
        try {
          const dsaPdfBlob =
            pdfGenerator.documentStream.toBlob('application/pdf');
          const fileName = pdfGenerator.getFileName();

          resolve([dsaPdfBlob, fileName]);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  async generatePDF({ type, data, isSampleDSA }) {
    if (type === 'dl-interview') {
      try {
        return await this.#generate({
          type: 'dl-interview',
          templatePath: this.interviewFiles[this.registrationType],
          data: null,
          isSampleDSA: false,
        });
      } catch (error) {
        console.error(
          `Unable to generate Downloadable Interview PDF: ${error}`,
        );
        throw new Error(
          `Unable to generate Downloadable Interview PDF: ${error}`,
        );
      }
    } else if (type === 'dsa') {
      try {
        return await this.#generate({
          type: 'dsa',
          templatePath: this.#getDSATemplatePath(data),
          data: data,
          isSampleDSA: isSampleDSA,
        });
      } catch (error) {
        console.error(`Unable to generate DSA PDF: ${error}`);
        throw new Error(`Unable to generate DSA PDF: ${error}`);
      }
    } else {
      throw new Error(`Unknown PDF type: ${type}`);
    }
  }
}

export default PDFGenerator;
