import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const EnvironmentContext = createContext();

export const EnvironmentProvider = ({ children }) => {
  const packages = require('../../package.json');

  const [environmentData, setEnvironmentData] = useState({});

  const localConfigs = localStorage.getItem('config') || 'configs/tne'; // Only set in local developer environment

  const [configsPath, setConfigsPath] = useState(
    process.env.NODE_ENV === 'development' ? localConfigs : 'configs',
  );

  const [userDN, setUserDN] = useState('');

  const [isEnvironmentDataLoaded, setIsEnvironmentDataLoaded] = useState(false);
  const [isQueryingEnvironmentDataLoaded, setIsQueryingEnvironmentDataLoaded] =
    useState(true);
  const [pctVersion] = useState(packages.version);

  useEffect(() => {
    const fetchEnvironmentData = async () => {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');

      const options = { retries: 3, delay: 1000 };

      for (let i = 0; i < options.retries; i++) {
        try {
          const response = await fetch(`${configsPath}/environment.json`);
          if (!response.ok) {
            throw new Error(
              `Response failed with status of, ${response.status}`,
            );
          } else {
            const jsonData = await response.json();

            setUserDN(
              response.headers.get('X-SSL-Client-S-DN') &&
                response.headers.get('X-SSL-Client-S-DN') !== '(null)'
                ? response.headers.get('X-SSL-Client-S-DN')
                : 'Unknown User',
            );

            setEnvironmentData(jsonData);
            setIsEnvironmentDataLoaded(true);
            setIsQueryingEnvironmentDataLoaded(false);

            break;
          }
        } catch (error) {
          console.error(`Fetch ${error.message} , retrying...`);
          if (i === options.retries - 1) {
            console.error('MissingData: Environment data could not be loaded.');
            setIsEnvironmentDataLoaded(false);
            setIsQueryingEnvironmentDataLoaded(false);
          }
        }
        await new Promise((resolve) => setTimeout(resolve, options.delay));
      }
    };

    fetchEnvironmentData();
  }, [configsPath]);

  return (
    <EnvironmentContext.Provider
      value={{
        environmentData,
        isEnvironmentDataLoaded,
        isQueryingEnvironmentDataLoaded,
        pctVersion,
        configsPath,
        setConfigsPath,
        userDN,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

EnvironmentProvider.propTypes = { children: PropTypes.node.isRequired };

export default EnvironmentContext;
