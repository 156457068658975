import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RegistrationNavBar = () => {
  return (
    <Navbar
      id="registrationNavBar"
      data-testid="registrationNavBar"
      variant="dark"
    >
      <Nav className="mx-auto">
        <Nav.Link
          id="registrationNavBarConsumerLink"
          data-testid="registrationNavBarConsumerLink"
          as={Link}
          to="/onboard/consumer/registration"
          className="nav-link"
          style={{ display: 'inline' }}
          active={location.pathname === '/onboard/consumer/registration'}
        >
          Register as
          <span className="nav-bar-registration-type"> Data Consumer</span>
        </Nav.Link>
        <span className="choose">OR</span>
        <Nav.Link
          id="registrationNavBarProviderLink"
          data-testid="registrationNavBarProviderLink"
          as={Link}
          to="/onboard/provider/registration"
          className="nav-link"
          style={{ display: 'inline' }}
          active={location.pathname === '/onboard/provider/registration'}
        >
          Register as
          <span className="nav-bar-registration-type"> Data Provider</span>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default RegistrationNavBar;
