import { useState, useEffect, useContext } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import OnboardingStateContext from './OnboardingStateContext';
import OnboardingDataContext from './OnboardingDataContext';

const OnboardingStepperNav = () => {
  const location = useLocation();

  const [interviewPointerEvents, setInterviewPointerEvents] = useState('none');

  const { onboardingStateMatrix } = useContext(OnboardingStateContext);
  const { registrationType } = useContext(OnboardingDataContext);

  const allowedPaths = [
    '/onboard',
    '/onboard/consumer/previewinterview',
    '/onboard/provider/previewinterview',
    '/onboard/consumer/thankyou',
    '/onboard/provider/thankyou',
    '/onboard/consumer/registration',
    '/onboard/provider/registration',
    '/onboard/consumer/interview',
    '/onboard/provider/interview',
    '/onboard/consumer/dsa',
    '/onboard/provider/dsa',
  ];

  const stepper = allowedPaths.includes(location.pathname) ? true : false;

  useEffect(() => {
    if (
      (registrationType === 'consumer' && onboardingStateMatrix[0][0] === 1) ||
      (registrationType === 'provider' && onboardingStateMatrix[1][0] === 1)
    ) {
      setInterviewPointerEvents('auto');
    } else {
      setInterviewPointerEvents('none');
    }
  }, [location.pathname, registrationType, onboardingStateMatrix]);

  return (
    <div id="onBoardingStepperNav" className="navbar-container">
      {stepper && (
        <Navbar
          data-testid="onBoardingStepperNav"
          expand="sm"
          variant="dark"
          className="py-0"
        >
          <Container className="navbar-container">
            <Nav className="row row-cols-auto navbar-collapse mx-1">
              <Nav.Link
                id="linkToStart"
                data-testid="start-nav.link"
                as={Link}
                to="/onboard"
                active={
                  location.pathname === '/onboard' ||
                  location.pathname === '/onboard/consumer/previewinterview' ||
                  location.pathname === '/onboard/provider/previewinterview'
                }
              >
                <span className="text-nowrap">
                  <Button
                    variant="btn btn-primary custom-btn-primary"
                    className="btn-med px-1"
                  >
                    <Badge
                      pill
                      bg="warning"
                      text="primary"
                      className="position-relative top-0 start-0 translate-middle rounded-circle"
                    >
                      0
                    </Badge>
                    Prepare
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="white"
                      className="fa-fw ms-sm-3"
                    />
                  </Button>
                </span>
              </Nav.Link>
              <Nav.Link
                id="linkToRegister"
                data-testid="registration-nav.link"
                as={Link}
                to={`/onboard/${registrationType}/registration`}
                active={
                  location.pathname === '/onboard/consumer/registration' ||
                  location.pathname === '/onboard/provider/registration' ||
                  location.pathname === '/onboard/consumer/thankyou' ||
                  location.pathname === '/onboard/provider/thankyou'
                }
              >
                <span className="text-nowrap">
                  <Button
                    variant="btn btn-primary custom-btn-primary"
                    className="btn-med px-1"
                  >
                    <Badge
                      pill
                      bg="warning"
                      text="primary"
                      className="position-relative top-0 start-0 translate-middle rounded-circle"
                    >
                      1
                    </Badge>
                    Enter Information
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="white"
                      className="fa-fw ms-sm-3"
                    />
                  </Button>
                </span>
              </Nav.Link>
              <Nav.Link
                id="linkToInterview"
                data-testid="interview-nav.link"
                as={Link}
                to={`/onboard/${registrationType}/interview`}
                style={{ pointerEvents: interviewPointerEvents }}
                active={
                  location.pathname === '/onboard/consumer/interview' ||
                  location.pathname === '/onboard/provider/interview'
                }
              >
                <span className="text-nowrap">
                  <Button
                    variant="btn btn-primary custom-btn-primary"
                    className="btn-med px-1"
                  >
                    <Badge
                      pill
                      bg="warning"
                      text="primary"
                      className="position-relative top-0 start-0 translate-middle rounded-circle"
                    >
                      2
                    </Badge>
                    Answer Questionnaire
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="white"
                      className="fa-fw ms-sm-3"
                    />
                  </Button>
                </span>
              </Nav.Link>
              <Nav.Link
                id="linkToDSAGenerator"
                data-testid="dsa-nav.link"
                as={Link}
                to={`/onboard/${registrationType}/dsa`}
                style={{ pointerEvents: 'none' }}
                active={
                  location.pathname === '/onboard/consumer/dsa' ||
                  location.pathname === '/onboard/provider/dsa'
                }
              >
                <span className="text-nowrap">
                  <Button
                    variant="btn btn-primary custom-btn-primary"
                    className="btn-med px-1"
                  >
                    <Badge
                      pill
                      bg="warning"
                      text="primary"
                      className="position-relative top-0 start-0 translate-middle rounded-circle"
                    >
                      3
                    </Badge>
                    Generate DSA
                  </Button>
                </span>
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      )}
    </div>
  );
  //  }
};

export default OnboardingStepperNav;
