export const dsaGeneratorFunctions = {
  _isPageBreakNeeded({
    text,
    y,
    options: { width: width = undefined, alignment: alignment },
  }) {
    let heightOfText = 0;

    if (width != undefined) {
      heightOfText = Math.ceil(
        y +
          this.document.heightOfString(text, {
            width: width,
            align: alignment,
          }),
      );
    } else {
      heightOfText = Math.ceil(
        y +
          this.document.heightOfString(text, {
            align: alignment,
          }),
      );
    }

    const heightOfContentArea = Math.ceil(
      this.document.page.height - this.document.page.margins.bottom,
    );

    return heightOfText >= heightOfContentArea - 5; // Add a 5 point buffer just to catch edge cases
  },

  _addPageBreak() {
    const currX = this.document.x; // Capture current value of x, this resets on new page.
    const marks = this.document.page.markings; // Carry markings over to new page.
    this.document.addPage({ size: 'LETTER' });
    this.document.page.markings = marks;
    this.document.x = currX; // return x to value left off from prior page
  },

  _checkSectionStartForPageBreak(title, body) {
    /* Get the estimated height of the title and the body. To do so temporarily set the font and fontSize.
    This is done so that the heightOfString returns actually estimated height of the text to be written.
    These will be set again by this._addText before text is written to page. */

    if (title.font && title.font !== 'default') {
      this.document.font(title.font);
    }

    this.document.fontSize(title.fontSize);
    const titleHeight = this.document.heightOfString(title.text, {
      align: title.alignment,
    });

    if (body.font && body.font !== 'default') {
      this.document.font(body.font);
    }

    this.document.fontSize(body.fontSize);
    const bodyHeight = this.document.heightOfString(body.text, {
      align: body.alignment,
    });

    const heightText = Math.ceil(this.document.y + titleHeight + bodyHeight);
    const heightContentArea = Math.ceil(
      this.document.page.height - this.document.page.margins.bottom,
    );

    return heightText >= heightContentArea - 20; // Add a 20 point buffer just to catch edge cases
  },

  _addText({ text, font, fontSize, fillColor, alignment, moveDown }) {
    if (font != 'default') {
      this.document.font(font);
    }

    this.document.fontSize(fontSize);
    this.document.fillColor(fillColor);

    // Don't let text blocks break across pages. Add a new page and then the text if risk of breaking across page.
    if (
      this._isPageBreakNeeded({
        text: text,
        y: this.document.y,
        options: { alignment: alignment },
      })
    ) {
      this._addPageBreak();
    }

    this.document.text(text, { align: alignment });

    this.document.moveDown(moveDown);
  },

  _setHeader() {
    this.document.markContent('P');
    for (const line of this.template.header.lines) {
      this._addText({
        text: line.text,
        font: this.fonts !== null ? line.font : 'default',
        fontSize: line.fontSize,
        fillColor: line.fillColor,
        alignment: line.alignment,
        moveDown: line.moveDown,
      });
    }
    this.document.endMarkedContent(); // 'P'

    const columnWidths = [
      this.template.header.table.colWidths['0'],
      this.template.header.table.colWidths['1'],
    ];

    // Get the starting x and y positions in the document and use them to build the table. Failing to do so results in misaligned content.
    let x = this.document.x;
    let y = this.document.y;

    let maxYPreviousRow = 0;

    this.document.markContent('Table');
    this.template.header.table.rows.forEach((row, rowIndex) => {
      // Reset y at start of each row. Failure to do so will result all table text being written on same line.
      if (maxYPreviousRow != 0) {
        y = maxYPreviousRow;
        maxYPreviousRow = 0;
      } else {
        y = this.document.y;
      }

      // Don't let text blocks break across pages. Check if any cell may have a break in it and add preemptively add one if so.
      let rowHasPageBreak = false;
      row.forEach((cell, cellIndex) => {
        if (!rowHasPageBreak) {
          const replacedText = cell.text.replace(
            /{{(.*?)}}/g,
            (match, key) => this.data[key.trim()],
          );

          if (
            this._isPageBreakNeeded({
              text: replacedText,
              y: y,
              options: {
                width: columnWidths[cellIndex],
                alignment: cell.alignment,
              },
            })
          ) {
            this._addPageBreak();

            // Must reset the y value so that it matches the start of the new page y value, not previous
            y = this.document.y;

            rowHasPageBreak = true;
          }
        }
      });

      this.document.markContent('TR');
      row.forEach((cell, cellIndex) => {
        this.document.markContent('TD');
        this.document.fontSize(cell.fontSize);

        if (cell.font !== undefined && cell.font !== 'default') {
          this.document.font(cell.font);
        }

        this.document.fillColor(cell.fillColor);
        if (cellIndex === 0) {
          // Text in a table should be highly controlled, do not use the _addText function here.
          this.document.text(
            cell.text,
            x + cellIndex * columnWidths[cellIndex],
            y,
            {
              width: columnWidths[cellIndex],
              align: cell.alignment,
            },
          );
        } else {
          let replacedText = cell.text.replace(
            /{{(.*?)}}/g,
            (match, key) => this.data[key.trim()],
          );
          // Text in a table should be highly controlled, do not use the _addText function here.
          this.document.text(
            replacedText,
            x + cellIndex * columnWidths[cellIndex - 1], // The start of this cell should be at the end of the prior cell.
            y,
            {
              width: columnWidths[cellIndex],
              align: cell.alignment,
            },
          );
        }
        this.document.moveDown(cell.moveDown);
        this.document.endMarkedContent(); // 'TD'
      });
      // We need to track largest y value written in a row, so that we can prevent text being overwritten in next row.
      // NOTE: Only do this if a page page didn't happen during the write that logic is handled differently.
      if (maxYPreviousRow < this.document.y) {
        maxYPreviousRow = this.document.y;
      }

      this.document.endMarkedContent(); // 'TR
    });
    this.document.moveDown(this.template.header.table.moveDown);
    this.document.endMarkedContent(); // 'Table

    // Failing to set document.x value to value x will result in document alignment issues
    this.document.x = x;
  },

  _setGeneralSections() {
    for (const section of this.template.sections) {
      const title = {
        text: section.title.text,
        font: this.fonts !== null ? section.title.font : 'default',
        fontSize: section.title.fontSize,
        align: section.title.alignment,
      };

      const body = {
        text: section.body[0].text,
        font: this.fonts !== null ? section.body[0].font : 'default',
        fontSize: section.body[0].fontSize,
        align: section.body[0].alignment,
      };

      // Page break if section title and first body text block risks being broken across pages.
      if (this._checkSectionStartForPageBreak(title, body)) {
        this._addPageBreak();
      }

      // Set section title
      this.document.markContent('H2');
      this._addText({
        text: section.title.text,
        font: this.fonts !== null ? section.title.font : 'default',
        fontSize: section.title.fontSize,
        fillColor: section.title.fillColor,
        alignment: section.title.alignment,
        moveDown: section.title.moveDown,
      });
      this.document.endMarkedContent(); // 'H2'

      for (const line of section.body) {
        this.document.markContent('P');
        this.document.fontSize(line.fontSize);

        if (line.font !== undefined && line.font !== 'default') {
          this.document.font(line.font);
        }

        this.document.fillColor(line.fillColor);

        if (line.bulletList === false) {
          this.document.text(line.text, {
            align: line.alignment,
          });
          this.document.moveDown(line.moveDown);
        } else {
          // Indent doesn't work exactly as expected with bullets, so as a hack use current values of doc's x and y coordinates to indent the bullets.
          let currX = this.document.x;
          let currY = this.document.y;
          this.document.list(line.text, currX + 20, currY, {
            bulletRadius: 2,
            textIndent: 20,
          });
          this.document.x = currX;
        }
        this.document.endMarkedContent(); // 'P'
      }
      this.document.moveDown(section.moveDown);
    }
  },

  _setPolicySection() {
    const title = {
      text: this.template.policySection.title.text,
      font:
        this.fonts !== null
          ? this.template.policySection.title.font
          : 'default',
      fontSize: this.template.policySection.title.fontSize,
      align: this.template.policySection.title.alignment,
    };

    const body = {
      text: this.template.policySection.body.text,
      font:
        this.fonts !== null ? this.template.policySection.body.font : 'default',
      fontSize: this.template.policySection.body.fontSize,
      align: this.template.policySection.body.alignment,
    };

    // Page break if section title and first body text block risks being broken across pages.
    if (this._checkSectionStartForPageBreak(title, body)) {
      this._addPageBreak();
    }

    // Set section title
    this.document.markContent('H2');
    this._addText({
      text: this.template.policySection.title.text,
      font:
        this.fonts !== null
          ? this.template.policySection.title.font
          : 'default',
      fontSize: this.template.policySection.title.fontSize,
      fillColor: this.template.policySection.title.fillColor,
      alignment: this.template.policySection.title.alignment,
      moveDown: this.template.policySection.title.moveDown,
    });
    this.document.endMarkedContent(); // 'H2'

    // Set body text
    this.document.markContent('P');
    this._addText({
      text: this.template.policySection.body.text,
      font:
        this.fonts !== null ? this.template.policySection.body.font : 'default',
      fontSize: this.template.policySection.body.fontSize,
      fillColor: this.template.policySection.body.fillColor,
      alignment: this.template.policySection.body.alignment,
      moveDown: this.template.policySection.body.moveDown,
    });
    this.document.endMarkedContent(); // 'P'

    /* When writing policies to the document we want to have them indented from the parent text. To do this we need to temporarily 
       set the document's x value to one that will give us the proper indent level. Ensure the document's x value is reset to the 
       starting value after the policies are done being written. Failing to do so will result in misaligned text in the document. */
    const startX = this.document.x;
    this.document.x = startX + 20; // Indent by 20 points.

    // Set policies
    this.document.markContent('L');
    const policiesEnforcedArray = this.data.policiesEnforced?.split('\n') || [];

    if (
      // When no policies are present
      policiesEnforcedArray.length === 1 &&
      policiesEnforcedArray.includes('')
    ) {
      this._addText({
        text: 'No Policies.',
        font:
          this.fonts !== null
            ? this.template.policySection.body.font
            : 'default',
        fontSize: this.template.policySection.body.fontSize,
        fillColor: this.template.policySection.body.fillColor,
        alignment: this.template.policySection.body.alignment,
        moveDown: 0,
      });
      this.document.endMarkedContent();
    } else {
      for (const policy of policiesEnforcedArray) {
        /* Prevent policies from breaking across pages by first calculating the height the policy string will be when it is printed in 
      the pdf. Then add the y (height) value where the string will be printed to on the PDF. If this value is less than page size - bottom margin
      it may be printed to current page, if not add a page and print it there. */

        // Don't let text blocks break across pages. Add a new page and then the text if risk of breaking across page.
        // NOTE: Check AFTER font is set or else this check may be incorrect. In this case we are just using body font as set above.
        const heightOfText = Math.ceil(
          this.document.y +
            this.document.heightOfString(`${policy}`, {
              align: 'left',
            }),
        );
        const heightOfContentArea = Math.ceil(
          this.document.page.height - this.document.page.margins.bottom,
        );

        if (heightOfText >= heightOfContentArea) {
          this._addPageBreak();
          this.document.x = startX + 20; // Have to reset the indent level after a page break.
        }
        this.document.markContent('LI');

        this._addText({
          text: policy,
          font:
            this.fonts !== null
              ? this.template.policySection.body.font
              : 'default',
          fontSize: this.template.policySection.body.fontSize,
          fillColor: this.template.policySection.body.fillColor,
          alignment: this.template.policySection.body.alignment,
          moveDown: 0,
        }); // Don't use body moveDown, policies should have no space between them.

        this.document.endMarkedContent(); // 'LI'
      }
    }
    this.document.moveDown(1);
    this.document.endMarkedContent(); // 'L'

    // Remember to reset document's x value to what it was before writing the policy section.
    this.document.x = startX;
  },

  _setSignatureSection(disableDigitalSignature) {
    /* The signature section is about 455 points long on the page. We don't want this section to split across pages. 
       So, add page if current y value plus 455 exceeds page size margin size. 

       NOTE: 455 is the manually calculated size of this section at the time this was written. This can change in the 
       future if content is added or subtracted from this section. This calculation is for three signature block max.
       There is no good method to precalculate this value  since it is made up of multiple text blocks and a table. 
       Value should be manually recalculated each time content is changed, or a better method of pre-calculating this  
       value will be need if more an unknown amount of rows are being added.*/

    const heightOfContentArea = Math.ceil(
      this.document.page.height - this.document.page.margins.bottom,
    );

    if (this.document.y + 455 >= heightOfContentArea) {
      const marks = this.document.page.markings; // Carry markings over to new page.
      this.document.addPage({ size: 'LETTER' });
      this.document.page.markings = marks;
    }

    // Set section title
    this.document.markContent('H2');
    this._addText({
      text: this.template.signatureSection.title.text,
      font:
        this.fonts !== null
          ? this.template.signatureSection.title.font
          : 'default',
      fontSize: this.template.signatureSection.title.fontSize,
      fillColor: this.template.signatureSection.title.fillColor,
      alignment: this.template.signatureSection.title.alignment,
      moveDown: this.template.signatureSection.title.moveDown,
    });
    this.document.endMarkedContent(); // 'H2'

    // Set body text
    this.document.markContent('P');
    this._addText({
      text: this.template.signatureSection.body.text,
      font:
        this.fonts !== null
          ? this.template.signatureSection.body.font
          : 'default',
      fontSize: this.template.signatureSection.body.fontSize,
      fillColor: this.template.signatureSection.body.fillColor,
      alignment: this.template.signatureSection.body.alignment,
      moveDown: this.template.signatureSection.body.moveDown,
    });
    this.document.endMarkedContent(); // 'P'

    // Annotation option for adding a digital signature
    const annotOptions = {
      FT: 'Sig',
    };

    // Build signature table.
    const columnWidths = [
      this.template.signatureSection.table.colWidths['0'],
      this.template.signatureSection.table.colWidths['1'],
    ];

    let x = this.document.x;
    let y = this.document.y;

    let maxYPreviousRow = 0;

    // Loop through the table data and add rows and cells to the table
    this.document.markContent('Table');
    this.template.signatureSection.table.rows.forEach((row, rowIndex) => {
      // Reset y at start of each row. Failure to do so will result all table text being written on same line.
      if (maxYPreviousRow != 0) {
        y = maxYPreviousRow;
        maxYPreviousRow = 0;
      } else {
        y = this.document.y;
      }

      var startRowY = y;

      // Don't let text blocks break across pages. Check if any cell may have a break in it and add preemptively add one if so.
      let rowHasPageBreak = false;
      row.forEach((cell, cellIndex) => {
        if (!rowHasPageBreak) {
          const replacedText = cell.text.replace(
            /{{(.*?)}}/g,
            (match, key) => this.data[key.trim()],
          );

          if (
            this._isPageBreakNeeded({
              text: replacedText,
              y: y,
              options: {
                width: columnWidths[cellIndex],
                alignment: cell.alignment,
              },
            })
          ) {
            this._addPageBreak();

            // Must reset the y value so that it matches the start of the new page y value, not previous
            y = this.document.y;

            rowHasPageBreak = true;
          }
        }
      });

      this.document.markContent('TR');
      row.forEach((cell, cellIndex) => {
        this.document.markContent('TD');

        if (cell.font !== undefined && cell.font !== 'default') {
          this.document.font(cell.font);
        }

        this.document.fontSize(cell.fontSize);
        this.document.moveDown(cell.moveDown);
        if (cell.text === 'Digital Signature:') {
          // Signature box cell
          this.document.text(
            cell.text,
            x + cellIndex * columnWidths[cellIndex],
            y,
            {
              width: columnWidths[cellIndex],
              align: cell.alignment,
            },
          );

          // Add digital signature overlay, only if disableDigitalSignature is false
          if (!disableDigitalSignature) {
            this.document.formAnnotation(
              `Signature Block ${String(rowIndex + 1)}`, // Make sure the signature block names are different from each other or else error will occur when opening in PDF app.
              null,
              x + cellIndex * columnWidths[cellIndex],
              this.document.y,
              columnWidths[cellIndex] - 50,
              50,
              annotOptions,
            );
          }
        } else {
          const replacedText = cell.text.replace(
            /{{(.*?)}}/g,
            (match, key) => this.data[key.trim()],
          );

          if (cell.text.startsWith(cell.cellTitle)) {
            const start = 0;
            const end = cell.cellTitle.length;

            // Text in a table should be highly controlled, do not use the _addText function here.
            if (cell.font && cell.font !== 'default') {
              this.document
                .font(cell.cellTitleFont)
                .text(
                  replacedText.slice(start, end),
                  x + cellIndex * columnWidths[cellIndex],
                  y,
                  {
                    width: columnWidths[cellIndex],
                    align: cell.alignment,
                  },
                )
                .font(cell.font)
                .text(
                  replacedText.slice(end),
                  x + cellIndex * columnWidths[cellIndex],
                  y,
                  {
                    width: columnWidths[cellIndex],
                    align: cell.alignment,
                  },
                )
                .moveDown(cell.moveDown);
            } else {
              this.document
                .text(
                  replacedText.slice(start, end),
                  x + cellIndex * columnWidths[cellIndex],
                  y,
                  {
                    width: columnWidths[cellIndex],
                    align: cell.alignment,
                  },
                )
                .text(
                  replacedText.slice(end),
                  x + cellIndex * columnWidths[cellIndex],
                  y,
                  {
                    width: columnWidths[cellIndex],
                    align: cell.alignment,
                  },
                )
                .moveDown(cell.moveDown);
            }
          } else {
            throw new Error('Cannot match cell text to title!');
          }
        }
        this.document.endMarkedContent(); // 'TD'

        // We want all rows to have a minimum height, so that signature blocks for each row do not end up overlapping.
        // Additionally, while the hight of a signature block is about 60, we use 90. This is to make the rows look more uniform.
        // The largest row is the customer's data, which is ~90, and the rest of the rows look better when equal to that row in size.
        var yDelta = this.document.y - startRowY;
        if (yDelta < 90) {
          this.document.y = this.document.y + (90 - yDelta);
        }

        if (maxYPreviousRow < this.document.y) {
          // We need to track largest y value written in a row, so that we can prevent text being overwritten in next row.
          maxYPreviousRow = this.document.y;
        }
      });
      this.document.endMarkedContent(); // 'TR'
    });
    this.document.endMarkedContent(); // 'Table
    this.document.moveDown(this.template.signatureSection.table.moveDown);

    // Failing to set document.x value to value x will result in document alignment issues
    this.document.x = x;
  },

  _addPageNumbers() {
    const pageRange = this.document.bufferedPageRange();

    // Always use document default font for page numbers.
    if (this.fonts != null) {
      this.document.font(this.template.documentSettings.defaultFont);
    }

    for (let i = 0; i < pageRange.count; i++) {
      this.document.switchToPage(i);

      this.document.y = 760;

      this.document.text(`${i + 1} of ${pageRange.count}`, {
        align: 'right',
        width: 500,
        height: 760,
      }); // Don't use _addText here.
    }
  },

  _addClassification() {
    const pageRange = this.document.bufferedPageRange();

    for (let i = 0; i < pageRange.count; i++) {
      this.document.switchToPage(i);

      // Header
      this.document.y = 20;
      this.document.font('Helvetica-Bold').text(`${this.classification}`, {
        align: 'center',
        width: 450,
        height: 20,
      }); // Don't use _addText here.

      // Footer
      this.document.y = 760;
      this.document.font('Helvetica-Bold').text(`${this.classification}`, {
        align: 'center',
        width: 450,
        height: 760,
      }); // Don't use _addText here.
    }
  },

  _addSampleWatermark() {
    const fontSize = 84;
    const opacity = 0.3;

    const pages = this.document.bufferedPageRange();

    for (let i = 0; i < pages.count; i++) {
      this.document.switchToPage(i);

      const xPosition = -525;
      const yPosition = 425;

      this.document.save();
      this.document.fontSize(fontSize);
      this.document.opacity(opacity);
      this.document.rotate(-45);
      this.document
        .font('Helvetica-Bold')
        .fillColor([10, 89, 178])
        .text('SAMPLE', xPosition, yPosition, {
          align: 'center',
          baseline: 'middle',
        });
      this.document.restore();
    }
  },

  getFileName() {
    return this.template.documentSettings.fileName;
  },

  generateDSA(hasWatermark = false) {
    // call to initForm is needed in order to allow an addition of the signature field annotation later.
    this.document.initForm();

    if (this.fonts) {
      for (const font of this.fonts) {
        this.document.registerFont(font.name, font.buffer);
      }
    }

    // DSA Title
    this.document.markContent('H1'); // Mark content sections to improve accessibility
    this._addText({
      text: this.template.title.text,
      font: this.fonts !== null ? this.template.title.font : 'default',
      fontSize: this.template.title.fontSize,
      fillColor: this.template.title.fillColor,
      alignment: this.template.title.alignment,
      moveDown: this.template.title.moveDown,
    });

    this.document.endMarkedContent(); // "H1"

    this._setHeader();

    this._setGeneralSections();

    this._setPolicySection();

    const disableDigitalSignature = hasWatermark;

    this._setSignatureSection(disableDigitalSignature);

    // End Text
    this.document.markContent('P');
    this._addText({
      text: this.template.end.text,
      font: this.fonts !== null ? this.template.end.font : 'default',
      fontSize: this.template.end.fontSize,
      fillColor: this.template.end.fillColor,
      alignment: this.template.end.alignment,
      moveDown: this.template.end.moveDown,
    });
    this.document.endMarkedContent(); // 'P'

    this._addPageNumbers();

    if (this.classification != '') {
      this._addClassification();
    }

    if (hasWatermark) {
      this._addSampleWatermark();
    }

    /*
     *  We have to drill down into the Document object and override the NeedAppearances property in order
     *  to prevent PDF readers from opening the generated DSA in a "dirty" state and asking the user to
     *  save when they close the PDF. We can do this because we do not use any other kinds of annotations
     *  in our DSA. Note that the NeedAppearances property is under the private _root property. We decided
     *  that changing this is an acceptable risk, but it means we will need to be aware of this when we
     *  update PDFKit in the future. They could change this behavior and cause issues for us. Also, if
     *  we wanted to add fields and field appearances they may not render properly when this is overridden.
     *
     *  See "Form Field Appearances" in the official PDFKit documentation on more info on this setting
     *  https://pdfkit.org/docs/guide.pdf
     *
     *  Issue #2 in PCT DSA Generator project provides more detail on why we chose to override this:
     *  https://gitlab.commondatafabric.net/cdf/cdf-development-section/pct/pct-pdf-generator/-/issues/2
     */
    this.document._root.data.AcroForm.data.NeedAppearances = false;

    this.document.end();
  },
};
