import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const RedmineConfigContext = createContext();

export const RedmineConfigProvider = ({ children }) => {
  const [redmineConfigs, setRedminConfigs] = useState(undefined);
  const [redmineSettings, setRedmineSettings] = useState({});
  const [isRedmineUnreachable, setIsRedmineUnreachable] = useState(false);
  const [isLoadingRedmineConfigs, setIsLoadingRedmineConfigs] = useState(true);
  const [isRedmineConfigsLoaded, setRedmineConfigsLoaded] = useState(false);

  useEffect(() => {
    if (
      !isLoadingRedmineConfigs &&
      redmineConfigs === undefined &&
      !isRedmineUnreachable
    ) {
      console.error('MissingData: Redmine configuration data was not loaded.');
      setIsRedmineUnreachable(true);
    }
  }, [isLoadingRedmineConfigs]);

  useEffect(() => {
    const loadMyScript = async () => {
      try {
        const module = await import(
          /* webpackIgnore: true */ '/common/redmine-config.js'
        );
        setRedminConfigs(module.default);
        setIsLoadingRedmineConfigs(false);
        setRedmineConfigsLoaded(true);
      } catch (error) {
        console.error('Failed to load the redmine-config script:', error);
        setIsLoadingRedmineConfigs(false);
        setRedmineConfigsLoaded(false);
      }
    };

    loadMyScript();
  }, []);

  return (
    <RedmineConfigContext.Provider
      value={{
        redmineConfigs,
        redmineSettings,
        setRedmineSettings,
        isRedmineUnreachable,
        setIsRedmineUnreachable,
        isRedmineConfigsLoaded,
        isLoadingRedmineConfigs,
      }}
    >
      {children}
    </RedmineConfigContext.Provider>
  );
};

RedmineConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RedmineConfigContext;
