import flatten from 'lodash/flatten';

export const getPolicyStr = (
  policyList,
  jsonAnswers,
  registrationType,
  policyFragmentPrefixConsumer,
  policyFragmentPrefixProvider,
  policyFragmentSuffix,
) => {
  // TODO - fix situation where conditional child policies are applied despite parent conditional is negative
  // e.g., where RMF categorizations are still applied when RMF===No (happens somehow when toggling several times)
  return stringifyPolicies(
    Object.entries(policyList)
      .filter(function (value) {
        // filter only active policies objects
        return policyList[value[0]][jsonAnswers[value[0]]]
          ? policyList[value[0]][jsonAnswers[value[0]]]
          : false;
      })
      .map(function (value) {
        // apply policy based on answer
        return policyList[value[0]][jsonAnswers[value[0]]];
      }),
    registrationType,
    policyFragmentPrefixConsumer,
    policyFragmentPrefixProvider,
    policyFragmentSuffix,
  );
};

export const stringifyPolicies = (
  policies,
  registrationType,
  policyFragmentPrefixConsumer,
  policyFragmentPrefixProvider,
  policyFragmentSuffix,
) => {
  let policyStr = '';
  const prefix =
    registrationType === 'consumer'
      ? policyFragmentPrefixConsumer
      : policyFragmentPrefixProvider;
  const policiesFlat = flatten(policies);
  policiesFlat.forEach((policy) => {
    policyStr += prefix + policy + policyFragmentSuffix;
  });
  // Dedupe policy list
  var pieces = policyStr.split('\n');
  var output = [];
  for (var i = 0; i < pieces.length; i++) {
    if (output.indexOf(pieces[i]) < 0) {
      output.push(pieces[i]);
    }
  }
  var newStr = output.join('\n');
  return newStr;
};

export const getQuestionToAnswerStrings = (form, submissionAnswers) => {
  const questionToAnswerStrings = [];

  form.components.forEach((component) => {
    component.components.forEach((component) => {
      const isKeyInSubmissionAnswers = component.key in submissionAnswers;

      if (isKeyInSubmissionAnswers && component.key !== 'policiesEnforced') {
        questionToAnswerStrings.push(
          component.label + ' ' + submissionAnswers[component.key],
        );
      }
    });
  });

  return questionToAnswerStrings;
};
