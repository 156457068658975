import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import PCTAlertContext from './PCTAlertContext.js';
import EnvironmentContext from './EnvironmentContext';

function PCTAlert() {
  const [alert, setAlert] = useContext(PCTAlertContext);
  const { environmentData, isEnvironmentDataLoaded } =
    useContext(EnvironmentContext);

  const { show, variant, heading, text, textComponent, info, buttons } = alert;

  const currentLocation = useLocation();

  const [initialLocation] = useState(currentLocation);

  useEffect(() => {
    // We want the alert to close when the user navigates from screen where error was shown
    if (currentLocation !== initialLocation) {
      handleClose();
    }
  }, [useLocation()]);

  const renderButtons = () => {
    return buttons.map((button, index) => {
      return (
        <Button
          id={button.id}
          data-testid={button.id}
          key={index}
          onClick={() => {
            button.onClick();
            handleClose();
          }}
          variant={button.variant}
        >
          {button.label}
        </Button>
      );
    });
  };

  const checkTextForHelpEmail = () => {
    const helpDeskRegex = /({{pctHelpDeskEmail}})/g;

    const email = isEnvironmentDataLoaded
      ? environmentData.pctHelpDeskEmail
      : '';

    if (text.includes('{{pctHelpDeskEmail}}')) {
      const parts = text.split(helpDeskRegex);
      const elements = parts.map((part, i) => {
        if (part === '{{pctHelpDeskEmail}}') {
          return (
            <Alert.Link key={i} href={`mailto:${email}`}>
              {email}
            </Alert.Link>
          );
        } else {
          return <span key={i}>{part}</span>;
        }
      });
      return elements;
    }
    return text;
  };

  const handleClose = () => {
    // To prevent a recursive call loop we must prevent setAlert from being called when the alert isn't showing
    if (show) {
      setAlert({ ...alert, show: false });
    }
  };

  return (
    <Alert id="pctalert" show={show} variant={variant}>
      <Alert.Heading>{heading}</Alert.Heading>
      <p>{checkTextForHelpEmail()}</p>
      <p>{info}</p>
      <div>{textComponent}</div>
      <hr />
      <div className="d-flex justify-content-end">
        {renderButtons()}
        <Button onClick={handleClose} variant={'outline-' + variant}>
          Close
        </Button>
      </div>
    </Alert>
  );
}

export default PCTAlert;
