import React, { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import OnboardingStepperNav from './OnboardingStepperNav';
import CommonSettingsContext from './CommonSettingsContext';
import EnvironmentContext from './EnvironmentContext';
import RedmineConfigContext from './RedmineConfigContext.js';

function Header() {
  const {
    environmentData,
    isEnvironmentDataLoaded,
    isQueryingEnvironmentDataLoaded,
  } = useContext(EnvironmentContext);

  const { isCommonSettingsDataLoaded, isQueryingCommonSettings } = useContext(
    CommonSettingsContext,
  );

  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const {
    isRedmineUnreachable,
    isLoadingRedmineConfigs,
    isRedmineConfigsLoaded,
  } = useContext(RedmineConfigContext);

  useEffect(() => {
    if (
      isRedmineUnreachable ||
      !isEnvironmentDataLoaded ||
      !isCommonSettingsDataLoaded ||
      !isRedmineConfigsLoaded
    ) {
      setShowWarningMessage(true);
    }
  }, [
    isRedmineUnreachable,
    isEnvironmentDataLoaded,
    isCommonSettingsDataLoaded,
    isRedmineConfigsLoaded,
  ]);

  const warningBannerMessage = () => {
    if (
      !isQueryingEnvironmentDataLoaded &&
      !isLoadingRedmineConfigs &&
      !isEnvironmentDataLoaded &&
      isRedmineConfigsLoaded
    ) {
      return (
        <div id="pct-warning-banner">
          <Alert key="danger" variant="danger">
            <p>Error: PCT environment data could not be loaded.</p>
          </Alert>
        </div>
      );
    } else if (!isQueryingCommonSettings && !isCommonSettingsDataLoaded) {
      return (
        <div id="pct-warning-banner">
          <Alert key="danger" variant="danger">
            <p>Error: PCT common settings data could not be loaded.</p>
          </Alert>
        </div>
      );
    } else if (isRedmineUnreachable) {
      return (
        <div id="pct-warning-banner">
          <Alert key="danger" variant="danger">
            <p>
              We are unable to connect to the Redmine server at the moment. This
              issue will prevent you from using the PCT.
            </p>
            <p>
              Please wait a few minutes and refresh the page. If the problem
              continues, please contact the PCT Help Desk for assistance:&nbsp;
              <Alert.Link
                key={environmentData.pctHelpDeskEmail}
                href={`mailto:${environmentData.pctHelpDeskEmail}`}
              >
                {environmentData.pctHelpDeskEmail}
              </Alert.Link>
            </p>
          </Alert>
        </div>
      );
    }
  };

  return (
    <div id="pctHeader">
      <nav>
        <Container>
          <div className="row">
            <NavBar />
          </div>
        </Container>
        <nav id="headerIdentity" className="header-nav">
          <Container>
            <div className="row">
              <div className="d-flex mx-2 px-2 pb-2 justify-content-between">
                <Link
                  id="headerLinkToHome"
                  to="/"
                  className="text-decoration-none"
                >
                  <img
                    id="headerCDFWaffle"
                    src="/content/images/cdf-waffle.svg"
                    alt="DISE Logo"
                    height="50"
                    style={{
                      display: 'inline-block',
                    }}
                  />
                  <span
                    style={{
                      color: 'white',
                      fontFamily: 'Tahoma',
                      fontSize: '1.5em',
                      paddingLeft: '5px',
                      verticalAlign: 'center',
                    }}
                  >
                    <b>Common Data Fabric</b>
                  </span>
                </Link>
                <span
                  style={{
                    color: 'white',
                    fontFamily: 'Segoe UI, sans-serif',
                    fontWeight: 'normal',
                    fontSize: '1.5em',
                  }}
                >
                  CDF Portal
                </span>
              </div>
            </div>
            {showWarningMessage ? warningBannerMessage() : null}
          </Container>
          <OnboardingStepperNav></OnboardingStepperNav>
        </nav>
      </nav>
    </div>
  );
}

export default Header;
