import { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from '@formio/react';

import PoliciesAttachedViewer from './PoliciesAttachedViewer.js';
import Footer from './Footer';
import Indicator from './Indicator.js';
import FormLoadError, { RedirectInterview } from './FormLoadError.js';
import PCTAlertContext from './PCTAlertContext.js';
import EnvironmentContext from './EnvironmentContext';
import CommonSettingsContext from './CommonSettingsContext';
import RedmineConfigContext from './RedmineConfigContext.js';
import OnboardingStateContext from './OnboardingStateContext';
import OnboardingDataContext from './OnboardingDataContext';
import { ModalAlertContext } from './ModalAlertQueue.js';

import PDFGenerator from '../PDFGenerator';
import detectPlatform from '../detect-platform.js';
import { getPCTStatusFromSubmissionData } from '../global-utils.js';
import { postRedmine, uploadPdfToRedmineIssue } from '../redmine-utils.js';
import { scrollToTop, filterUnrelatedFields, uc1 } from '../global-utils.js';
import { getPolicyStr, getQuestionToAnswerStrings } from '../policy-utils.js';
import { fetchJson } from '../fetch-json.js';
import {
  exportToJSONFile,
  importFromJSONFile,
} from '../export-import-form-data.js';

const Interview = () => {
  const { showModalAlert } = useContext(ModalAlertContext);
  const [, setAlert] = useContext(PCTAlertContext);
  const {
    environmentData,
    isEnvironmentDataLoaded,
    pctVersion,
    configsPath,
    userDN,
  } = useContext(EnvironmentContext);
  const { commonSettingsData, isCommonSettingsDataLoaded } = useContext(
    CommonSettingsContext,
  );
  const { redmineSettings, redmineConfigs, isRedmineUnreachable } =
    useContext(RedmineConfigContext);
  const {
    onboardingStateMatrix,
    setOnboardingStateMatrix,
    lastSubmittedIssueId,
    setLastSubmittedIssueId,
  } = useContext(OnboardingStateContext);
  const {
    interviewData,
    setInterviewData,
    registrationData,
    setRegistrationType,
    policiesEnforced,
    setPoliciesEnforced,
  } = useContext(OnboardingDataContext);

  const [form, setForm] = useState(null);
  const [errorLoadingForm, setErrorLoadingForm] = useState(false);
  const [redirectInterview, setRedirectInterview] = useState(false);
  const [policyList, setPolicyList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPoliciesAttachedPanelEnabled, setPolicyDiffPanelEnabled] = useState(
    sessionStorage.getItem('isPoliciesAttachedPanelEnabled') === 'true',
  );
  const [showResetFormDialog, setShowResetFormDialog] = useState(false);
  const [formVersion, setFormVersion] = useState('');
  const [baselineFiles, setBaselineFiles] = useState(null);
  const [submissionData, setSubmissionData] = useState({ data: {} });
  const [isSampleDSA, setIsSampleDSA] = useState(
    !environmentData.environment.toLowerCase().includes('prod'),
  );
  const [showIsSampleDSADialog, setShowIsSampleDSADialog] = useState(false);

  const formInstanceRef = useRef(null);
  const isMountedRef = useRef(null);
  const isSampleDSARef = useRef(isSampleDSA);
  const redmineSettingsRef = useRef(redmineSettings);
  const environmentDataRef = useRef(environmentData);
  const pctVersionRef = useRef(pctVersion);
  const configsPathRef = useRef(configsPath);
  const userDNRef = useRef(userDN);
  const registrationDataRef = useRef(registrationData);
  const redmineConfigsRef = useRef(redmineConfigs);
  const commonSettingsDataRef = useRef(commonSettingsData);
  const submissionDataRef = useRef(submissionData);
  const formVersionRef = useRef(formVersion);

  const { registrationTypeFromURL } = useParams();

  const handleCloseFormDialog = () => setShowResetFormDialog(false);
  const handleShowClearFormDialog = () => setShowResetFormDialog(true);
  const handleShowIsSampleDSADialog = () => setShowIsSampleDSADialog(true);
  const handleCloseIsSampleDSADialog = () => setShowIsSampleDSADialog(false);
  const navigate = useNavigate();
  const alertText =
    'Are you sure you want to reset the ' +
    registrationTypeFromURL +
    ' interview form?';

  const abortController = new AbortController();
  const { signal } = abortController;
  const filterString =
    registrationTypeFromURL === 'consumer' ? 'provider*' : 'consumer*';

  let interviewJsonPath = `/${configsPathRef.current}/forms/formio-interview-prime-${registrationTypeFromURL}.json`;
  let isSubmitting = false;
  let originalSubmitButtonContent;

  if (
    !errorLoadingForm &&
    (!isEnvironmentDataLoaded || !isCommonSettingsDataLoaded)
  ) {
    setErrorLoadingForm(true);
  }

  const filters = [
    filterString,
    'policiesEnforced',
    'submissionSkipOrSendNotification',
    'policyMatrixVersion',
    'pctVersion',
    'requestId',
    'saveAndProceed',
    'os',
    'pctVersion',
    'networkLabel',
    'browser',
    'registrationData',
    'jsonDataDump',
    'policyMatrixVersion',
    'questionsToAnswers',
    'interviewData',
  ];

  const modifyStateMatrix = (modifications) => {
    const updatedMatrix = [...onboardingStateMatrix];

    for (const { row, col, value } of modifications) {
      updatedMatrix[row][col] = value;
    }

    setOnboardingStateMatrix(updatedMatrix);
  };

  const pushHistory = (interviewFormData, dsaFileName, dsaBlob) => {
    setInterviewData(() => ({
      ...interviewData,
      [registrationTypeFromURL]: { data: interviewFormData },
    }));

    if (registrationTypeFromURL === 'consumer') {
      modifyStateMatrix([{ row: 0, col: 1, value: 1 }]);
    } else {
      modifyStateMatrix([{ row: 1, col: 1, value: 1 }]);
    }

    navigate(`/onboard/${registrationTypeFromURL}/dsa`, {
      state: {
        dsaFileName: dsaFileName,
        dsaBlob: dsaBlob,
      },
    });
  };

  const setIsPoliciesAttachedPanelEnabled = () => {
    sessionStorage.setItem(
      'isPoliciesAttachedPanelEnabled',
      !isPoliciesAttachedPanelEnabled,
    );

    setPolicyDiffPanelEnabled(
      (isPoliciesAttachedPanelEnabled) => !isPoliciesAttachedPanelEnabled,
    );
  };

  const hideFormioDefaultAlert = () => {
    // This hides the default formio success alert
    const formioAlert = document.querySelector('[id^="error-list-"]');
    if (formioAlert) {
      formioAlert.style.display = 'none';
    }
  };

  const getDsaFileName = (
    jsonDataSubmitted,
    registrationTypeFromURL,
    networkLabel,
  ) => {
    // FORMAT = <system name> (<por major system>) Consumer|Provider DSA on <network>-timestamp.pdf
    const timestamp = new Date().toISOString().replace(/[:.]/g, '');
    const systemName =
      jsonDataSubmitted[`${registrationTypeFromURL}_systemName`] ||
      'SystemNameNotSet';
    const porMajorSystem =
      jsonDataSubmitted[`${registrationTypeFromURL}_porMajorSystem`] ||
      'PorMajorSystemNotSet';

    const dsaFilename = `${systemName.replace(
      /[ ]/g,
      '',
    )} (${porMajorSystem.replace(/[ ]/g, '')}) ${uc1(
      registrationTypeFromURL,
    )} DSA on ${networkLabel} - ${timestamp}.pdf`;
    return dsaFilename;
  };

  const generateDSA = async (interviewFormData, redmineIssueID, policies) => {
    const [dsaData] = setupDSA(interviewFormData, redmineIssueID, policies);

    const pdfGenerator = new PDFGenerator({
      environmentData: environmentDataRef.current,
      registrationType: registrationTypeFromURL,
      pctVersion: pctVersionRef.current,
      policyMatrixVersion: form.properties.version,
      configsPath: configsPathRef.current,
    });

    const [dsaPdfBlob] = await pdfGenerator.generatePDF({
      type: 'dsa',
      data: dsaData,
      isSampleDSA: isSampleDSARef.current,
    });

    const dsaFileName = getDsaFileName(
      dsaData,
      dsaData.registrationTypeFromURL,
      environmentDataRef.current['networkLabel'],
    );

    return [dsaPdfBlob, dsaFileName];
  };

  const postRedmineIssue = async (rmIssue) => {
    const response = await postRedmine(
      {
        issue: rmIssue,
        redmineIssuesEndpoint:
          redmineConfigsRef.current.redmineApiIssuesEndpoint,
      },
      environmentDataRef.current.environment,
    );

    if (!response.ok) {
      throw new Error(commonSettingsDataRef.current['interviewSubmitError']);
    }

    const redmineIssueDetails = await response.json();

    return redmineIssueDetails;
  };

  const setupDSA = (interviewFormData, redmineIssueID, policies) => {
    const dsaData = {
      ...interviewFormData,
      ...registrationDataRef.current[registrationTypeFromURL].data,
    };
    dsaData['policiesEnforced'] = policies;

    dsaData.registrationTypeFromURL = registrationTypeFromURL;

    dsaData.networkLabel = environmentDataRef.current['networkLabel'];

    // Phone numbers have masks applied to them. We want to modify the phone number string to prepend this mask to it.
    const repPhone = dsaData[`${registrationTypeFromURL}_repPhone_field`].value;
    const repPhoneMask =
      dsaData[`${registrationTypeFromURL}_repPhone_field`].maskName;

    dsaData[`${registrationTypeFromURL}_repPhone`] =
      `${repPhoneMask}: ${repPhone}`;

    dsaData['redmineRequestId'] = redmineIssueID;

    return [dsaData];
  };

  const createRedmineIssue = async (
    interviewFormData,
    redmineIssueFields,
    policies,
    questionsToAnswers,
  ) => {
    redmineIssueFields.policyMatrixVersion = form.properties.version;
    redmineIssueFields.pctVersion = pctVersionRef.current;
    redmineIssueFields.submitterName = userDNRef.current;

    // Create Redmine issue
    let customRedmineIssueFields = {
      ...redmineIssueFields,
      ...registrationDataRef.current[registrationTypeFromURL].data,
    };

    // Phone numbers have masks applied to them. We want to modify the phone number string to prepend this mask to it.
    const repPhone =
      registrationDataRef.current[registrationTypeFromURL].data[
        `${registrationTypeFromURL}_repPhone_field`
      ].value;
    const repPhoneMask =
      registrationDataRef.current[registrationTypeFromURL].data[
        `${registrationTypeFromURL}_repPhone_field`
      ].maskName;
    const techPocPhone =
      registrationDataRef.current[registrationTypeFromURL].data[
        `${registrationTypeFromURL}_techPocPhone_field`
      ].value;
    const techPocPhoneMask =
      registrationDataRef.current[registrationTypeFromURL].data[
        `${registrationTypeFromURL}_techPocPhone_field`
      ].maskName;

    customRedmineIssueFields[`${registrationTypeFromURL}_repPhone`] =
      `${repPhoneMask}: ${repPhone}`;
    customRedmineIssueFields[`${registrationTypeFromURL}_techPocPhone`] =
      `${techPocPhoneMask}: ${techPocPhone}`;

    if (policies === '') {
      customRedmineIssueFields['policiesEnforced'] =
        'No policies were generated by this interview.';
    } else {
      customRedmineIssueFields['policiesEnforced'] = policies;
    }

    const platform = detectPlatform();

    customRedmineIssueFields['browser'] =
      platform.name + ' v' + platform.version;
    customRedmineIssueFields['os'] = platform.os;

    customRedmineIssueFields['networkLabel'] =
      environmentDataRef.current.networkLabel;

    // Get the PCT Status but don't put it into data until after dump.
    const pctStatus = getPCTStatusFromSubmissionData(
      filterUnrelatedFields(customRedmineIssueFields, ['registrationData']),
      registrationTypeFromURL,
    );

    customRedmineIssueFields['pctVersion'] = pctVersionRef.current;
    customRedmineIssueFields['policyMatrixVersion'] = form.properties.version;

    const jsonDump = JSON.stringify(customRedmineIssueFields, null, 2);
    customRedmineIssueFields['jsonDataDump'] = jsonDump;

    customRedmineIssueFields['pctStatus'] = pctStatus;

    customRedmineIssueFields['registrationData'] = JSON.stringify(
      registrationDataRef.current[registrationTypeFromURL].data,
      null,
      2,
    );

    customRedmineIssueFields['interviewData'] = JSON.stringify(
      interviewFormData,
      null,
      2,
    );

    let rmConfigsTracker = '';

    let subject = '';

    if (registrationTypeFromURL === 'consumer') {
      rmConfigsTracker =
        redmineConfigsRef.current.tracker_templates.consumerOnboardRequest;
      if (
        customRedmineIssueFields['consumer_visitPurpose'] === 'updateDsaYes'
      ) {
        subject =
          'UPDATE EXISTING DSA - ' +
          'Consumer Onboarding Request and DSA generated for ' +
          customRedmineIssueFields['consumer_porMajorSystem'] +
          ' on ' +
          environmentDataRef.current['networkLabel'];
      } else {
        subject =
          'Consumer Onboarding Request and DSA generated for ' +
          customRedmineIssueFields['consumer_porMajorSystem'] +
          ' on ' +
          environmentDataRef.current['networkLabel'];
      }
    } else if (registrationTypeFromURL === 'provider') {
      rmConfigsTracker =
        redmineConfigsRef.current.tracker_templates.providerDSASubmission;
      if (customRedmineIssueFields['provider_visitPurpose'] === 'dsaRenewal') {
        subject =
          'UPDATE EXISTING DSA - ' +
          'Provider DSA generated for ' +
          customRedmineIssueFields['provider_porMajorSystem'] +
          ' on ' +
          environmentDataRef.current['networkLabel'];
      } else {
        subject =
          'Provider DSA generated for ' +
          customRedmineIssueFields['provider_porMajorSystem'] +
          ' on ' +
          environmentDataRef.current['networkLabel'];
      }
    }

    let project_name = '';
    let project_id = 0;

    if (environmentDataRef.current.environment === 'dev-no-redmine') {
      project_name = 'Dev Local Test Data';
      project_id = redmineSettingsRef.current.projects[project_name].id;
    } else {
      project_name = environmentDataRef.current.redmineProjectName;
      project_id = redmineSettingsRef.current.projects[project_name].id;
    }

    customRedmineIssueFields['questionsToAnswers'] = questionsToAnswers;

    let issueData = {
      registrationType: registrationTypeFromURL,
      subject: subject,
      custom_fields: customRedmineIssueFields,
    };

    let rmIssue = {};

    try {
      rmIssue = rmConfigsTracker.create_tracker({
        redmineProjectSettings:
          redmineSettingsRef.current.projects[project_name],
        issueData: issueData,
      });
    } catch (error) {
      console.error('Error creating Redmine Issue', error);
      throw new Error('Cannot Create Redmine Issue');
    }

    rmIssue['project_id'] = project_id;

    return rmIssue;
  };

  const attachDSAToRedmineIssue = async (issueId, dsaPdfBlob, dsaFileName) => {
    const dsaArrayBuffer = await dsaPdfBlob.arrayBuffer();

    let didAttach = true;
    // Do not attach DSAs to issues to which a DSA was already attached.
    if (lastSubmittedIssueId !== issueId) {
      didAttach = await uploadPdfToRedmineIssue(
        issueId,
        dsaArrayBuffer,
        dsaFileName,
      );
    }

    return didAttach;
  };

  const startSpinnerOnSubmitButton = () => {
    const spinnerButton = document.getElementsByClassName(
      'btn btn-primary btn-wizard-nav-submit',
    )[0];

    if (spinnerButton) {
      originalSubmitButtonContent = spinnerButton.innerHTML;
      spinnerButton.innerHTML =
        '<div class="spinner-submission-border"><span class="spinner-border spinner-border-sm"  role="status" ></span><div>';
    }
  };

  const stopSpinnerOnSubmitButton = () => {
    const spinnerButton = document.getElementsByClassName(
      'btn btn-primary btn-wizard-nav-submit',
    )[0];

    if (spinnerButton) {
      spinnerButton.innerHTML = originalSubmitButtonContent;
      document
        .querySelectorAll('.fa-refresh.fa-spin.button-icon-right')
        .forEach(function (element) {
          element.parentNode.removeChild(element);
        });
    }
  };

  const submitForm = async (
    interviewFormData,
    policies,
    redmineIssueFields,
    questionsToAnswers,
  ) => {
    if (isMountedRef.current) {
      startSpinnerOnSubmitButton(true);

      scrollToTop();

      isSubmitting = true;

      let didDSAAttach = false;
      let redmineIssueDetails = {};
      let dsaFileName = '';
      let dsaPdfBlob = null;

      // We want to separate out the errors that can occur when attaching a DSA from those that can occur when creating the Redmine issue.
      // This prevents us from having the system create two or more issues if the user experiences an issue in the upload step. While we will
      // not have a copy of the DSA, we will have all the data to recreate it if needed, and we will not have to deal with multiple Redmine
      // issues for the same onboarding.
      try {
        const rmIssue = await createRedmineIssue(
          interviewFormData,
          redmineIssueFields,
          policies,
          questionsToAnswers,
        );

        redmineIssueDetails = await postRedmineIssue(
          rmIssue,
          interviewFormData,
        );
      } catch (error) {
        console.error('Failed to create and POST Redmine issue: ', error);

        setAlert({
          show: true,
          variant: 'danger',
          heading: 'Warning',
          text: commonSettingsDataRef.current[
            'redmineIssueCreationAtInterviewError'
          ],
          buttons: [],
        });

        hideFormioDefaultAlert();

        isSubmitting = false;

        stopSpinnerOnSubmitButton(false);

        return;
      }

      try {
        [dsaPdfBlob, dsaFileName] = await generateDSA(
          interviewFormData,
          redmineIssueDetails.issue.id.toString(),
          policies,
        );
      } catch (error) {
        console.error('Failed to Generate DSA: ', error);

        setAlert({
          show: true,
          variant: 'danger',
          heading: 'Warning',
          text: commonSettingsDataRef.current['dsaFailedToGenerate'],
          buttons: [],
        });

        hideFormioDefaultAlert();

        isSubmitting = false;

        stopSpinnerOnSubmitButton(false);

        return;
      }

      try {
        didDSAAttach = await attachDSAToRedmineIssue(
          redmineIssueDetails.issue.id,
          dsaPdfBlob,
          dsaFileName,
        );

        if (didDSAAttach) {
          setLastSubmittedIssueId(redmineIssueDetails.issue.id);

          // Handle successful response
          showModalAlert({
            modalAlertBody:
              `Reference ID #: ${redmineIssueDetails.issue.id}\n` +
              commonSettingsDataRef.current['interviewSubmitSuccess'],
            modalBody: (
              <div
                id="submissionSuccessSteps"
                data-testid="submissionSuccessSteps"
              >
                <b>Next Steps:</b>
                <p>
                  Download and review the DSA for accuracy and completeness.
                  Forward the DSA to the designated signatory for digital
                  signature, and return it via email to the CDF team at{' '}
                  <b>
                    <a
                      href={`mailto:${environmentDataRef.current.pctHelpDeskEmail}`}
                    >
                      {environmentDataRef.current.pctHelpDeskEmail}
                    </a>
                  </b>
                </p>
              </div>
            ),
            modalAlertTitle: 'Submission Success',
            buttons: [{ label: 'OK', variant: 'primary' }],
          });
          pushHistory(interviewFormData, dsaFileName, dsaPdfBlob);
        } else {
          throw new Error('DSA did not attach to Redmine issue.');
        }
      } catch (error) {
        console.error('Failed to attach DSA to Redmine Issue: ', error);

        setAlert({
          show: true,
          variant: 'danger',
          heading: 'Warning',
          text: commonSettingsDataRef.current[
            'dsaDidNotAttachToRedmineIssueError'
          ],
          buttons: [
            {
              id: 'continueToDSAOnError',
              label: 'Continue',
              variant: 'outline-secondary',
              onClick: () => {
                pushHistory(interviewFormData, dsaFileName, dsaPdfBlob);
              },
            },
          ],
        });

        // This hides the default formio success alert
        hideFormioDefaultAlert();
        isSubmitting = false;

        stopSpinnerOnSubmitButton(false);
      }
    }
  };

  const handleChange = (jsonAnswers) => {
    // Only perform operations if Interview is still mounted
    if (isMountedRef.current) {
      if (jsonAnswers.changed != undefined) {
        setSubmissionData({ data: jsonAnswers.data });
      }
    }
  };

  const handleFormReady = (formInstance) => {
    formInstanceRef.current = formInstance;
  };

  const handleFormSubmit = async (submission) => {
    // Only perform operations if Interview is still mounted
    if (isSubmitting) {
      return;
    }

    // Start with filtering out all fields unrelated to this submission from formio submission data
    // This object will be used to save the form data for later use by the PCT.
    const interviewFormData = filterUnrelatedFields(submission.data, filters);

    // We need a second copy of the submission data for creating the redmine issue.
    // This object will have additional data added to it, so it needs to be mutable.
    let redmineIssueFields = filterUnrelatedFields(submission.data, filters);

    let policies = getPolicyStr(
      policyList,
      interviewFormData,
      registrationTypeFromURL,
      commonSettingsDataRef.current.policyFragmentPrefixConsumer,
      commonSettingsDataRef.current.policyFragmentPrefixProvider,
      commonSettingsDataRef.current.policyFragmentSuffix,
    );

    let questionsToAnswers = getQuestionToAnswerStrings(
      form,
      interviewFormData,
    ).join('\n\n');

    setPoliciesEnforced({
      ...policiesEnforced,
      [registrationTypeFromURL]: policies,
    });

    const formioAlert = document.querySelector('[id^="error-list-"]');

    if (policies === '') {
      if (formioAlert) {
        formioAlert.style.display = 'none';
      }
      showModalAlert({
        modalAlertTitle: 'No Policies Attached',
        modalAlertBody:
          'If you choose to continue with your submission. No policies will be attached to your DSA. Are you sure you would like to proceed?',
        backdrop: 'static',
        buttons: [
          {
            label: 'Continue',
            variant: 'warning',
            onClick: async () => {
              await submitForm(
                interviewFormData,
                policies,
                redmineIssueFields,
                questionsToAnswers,
              );
            },
          },
          { label: 'Cancel', variant: 'primary' },
        ],
      });
    } else {
      await submitForm(
        interviewFormData,
        policies,
        redmineIssueFields,
        questionsToAnswers,
      );
    }
  };

  const handleRender = () => {
    isMountedRef.current = true;
    if (
      (isEnvironmentDataLoaded &&
        !environmentDataRef.current.environment
          .toLowerCase()
          .includes('prod')) ||
      process.env.NODE_ENV === 'test'
    ) {
      window.formObj = {
        setSubmission: (submission) => {
          const newSubmission = JSON.parse(JSON.stringify(submission));
          setSubmissionData({ data: newSubmission });
        },
      };
    }
  };

  const handleNextOrPrevPage = () => scrollToTop();

  const handleResetForm = () => {
    setInterviewData(() => ({
      ...interviewData,
      [registrationTypeFromURL]: {},
    }));

    setSubmissionData({ data: {} });

    if (registrationTypeFromURL === 'consumer') {
      modifyStateMatrix([
        { row: 0, col: 0, value: 1 },
        { row: 0, col: 1, value: 0 },
        { row: 0, col: 2, value: 0 },
      ]);
    } else {
      modifyStateMatrix([
        { row: 1, col: 0, value: 1 },
        { row: 1, col: 1, value: 0 },
        { row: 1, col: 2, value: 0 },
      ]);
    }
  };

  const handleExportToJSONFile = () => {
    exportToJSONFile({
      formData: submissionDataRef.current,
      formType: 'Interview',
      pctVersion: pctVersionRef.current,
      registrationType: registrationTypeFromURL,
      formVersion: formVersionRef.current,
      filters: filters,
    });
  };

  const handleImportFromJSONFile = async () => {
    try {
      const parsedData = await importFromJSONFile();

      if (parsedData._metadata === undefined) {
        alert(
          'File metadata field is missing Please upload a valid JSON file.',
        );
        return;
      }

      if (parsedData._metadata.registrationType !== registrationTypeFromURL) {
        alert(
          `The data you are uploading is for a ${parsedData._metadata.registrationType} interview.\n\nPlease upload a file for ${registrationTypeFromURL} interview or switch registration types.`,
        );
        return;
      }

      if (
        parsedData._metadata &&
        parsedData._metadata.dataType !== 'Interview'
      ) {
        alert(
          `The data you are uploading is for the ${parsedData._metadata.dataType} form.\n\nPlease upload a file for Interview form`,
        );
        return;
      }

      if (
        parsedData._metadata.formVersion !==
        formVersionRef.current.replace('Form Version: ', '')
      ) {
        alert(
          'The data you are uploading is from an older version of this form.\n\nPlease check for missing or incorrect data before submitting the form with this data.',
        );
      }

      setSubmissionData({ data: parsedData.data });
    } catch (error) {
      console.error('Error importing file:', error.message);
    }
  };

  const handleTestDataFill = async (testDataPath) => {
    setSubmissionData(await fetchJson(testDataPath));
  };

  const addBaselineFillDropdownItems = () => {
    const items = Object.entries(baselineFiles[registrationTypeFromURL]).map(
      ([label, fileName]) => (
        <Dropdown.Item
          key={label}
          data-testid={
            label
              .replace(/\s+/g, '')
              .replace(/^./, (char) => char.toLowerCase()) + 'DropDownItem'
          }
          onClick={() => {
            handleTestDataFill(`/${configsPathRef.current}/${fileName}`);
          }}
        >
          {label}
        </Dropdown.Item>
      ),
    );

    items.unshift(
      <Dropdown.ItemText key="item-text" className="text-muted">
        Test Data
      </Dropdown.ItemText>,
    );

    items.push(<Dropdown.Divider key="divider" />);

    return items;
  };

  //Mount redirect interview component
  useEffect(() => {
    if (!errorLoadingForm) {
      if (
        (registrationTypeFromURL === 'consumer' &&
          onboardingStateMatrix[0][0] === 0) ||
        (registrationTypeFromURL === 'provider' &&
          onboardingStateMatrix[1][0] === 0)
      ) {
        setRedirectInterview(true);
        setFormVersion('');
      }
    }
  }, []);

  useEffect(() => {
    setRegistrationType(registrationTypeFromURL);
  }, []);

  // Cleanup items when component unmounts
  useEffect(() => {
    if (!errorLoadingForm) {
      return () => {
        isMountedRef.current = false;
        window.formObj = null;
        window.policyList = null;
      };
    }
  }, []);

  // Fetch and render formio form and policies list
  useEffect(() => {
    if (!errorLoadingForm) {
      const fetchData = async () => {
        try {
          const [form] = await Promise.all([
            fetchJson(interviewJsonPath, signal),
          ]);
          setForm(form);

          setFormVersion('Form Version: ' + form.properties.version);

          const components = form.components;

          let policiesFromForm = {};

          for (const componentKey in components) {
            if (!isNaN(Number(componentKey))) {
              const subComponents = components[componentKey].components;

              for (const subComponentKey in subComponents) {
                if (!isNaN(Number(subComponentKey))) {
                  if (subComponents[subComponentKey].type == 'radio') {
                    policiesFromForm[subComponents[subComponentKey].key] =
                      subComponents[subComponentKey].properties;
                  }
                }
              }
            }
          }
          setPolicyList(policiesFromForm);
          setLoading(false);
          window.policyList = policiesFromForm;
          setErrorLoadingForm(false);
        } catch (error) {
          setLoading(false);
          setErrorLoadingForm(true);
        }

        setSubmissionData({
          data: interviewData[registrationTypeFromURL].data,
        });

        return () => {
          abortController.abort();
        };
      };

      fetchData();
    }
  }, [isRedmineUnreachable]);

  useEffect(() => {
    const fetchBaselines = async () => {
      try {
        setBaselineFiles(
          await fetchJson(
            `/${configsPath}/baselines/interview-baselines.json`,
            signal,
          ),
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (!environmentData.environment.toLowerCase().includes('prod')) {
      fetchBaselines();
    }
  }, []);

  useEffect(() => {
    isSampleDSARef.current = isSampleDSA;
    redmineSettingsRef.current = redmineSettings;
    environmentDataRef.current = environmentData;
    pctVersionRef.current = pctVersion;
    configsPathRef.current = configsPath;
    userDNRef.current = userDN;
    registrationDataRef.current = registrationData;
    redmineConfigsRef.current = redmineConfigs;
    commonSettingsDataRef.current = commonSettingsData;
    submissionDataRef.current = submissionData;
    formVersionRef.current = formVersion;
  }, [
    isSampleDSA,
    redmineSettings,
    environmentData,
    pctVersion,
    configsPath,
    userDN,
    registrationData,
    redmineConfigs,
    commonSettingsData,
    submissionData,
    formVersion,
  ]);

  // Render a loading message if the data is not loaded yet.
  if (!errorLoadingForm) {
    if (loading) return <Indicator />;
  }

  return (
    <div
      className="main-formio-container"
      id="interview"
      data-testid="interview"
    >
      {redirectInterview ? (
        <RedirectInterview
          interviewPath={registrationTypeFromURL}
          selectedInterviewPath={
            registrationTypeFromURL.charAt(0).toUpperCase() +
            registrationTypeFromURL.slice(1)
          }
        />
      ) : errorLoadingForm ? (
        <FormLoadError />
      ) : (
        <div
          id="interviewForm"
          data-testid="interviewForm"
          className="App row"
          tabIndex="0"
        >
          <div
            id="formioInterviewForm"
            data-testid="formioInterviewForm"
            className="mt-1 col-lg-9"
          >
            <Form
              form={form}
              formReady={handleFormReady}
              onChange={handleChange}
              onSubmit={handleFormSubmit}
              onNextPage={handleNextOrPrevPage}
              onPrevPage={handleNextOrPrevPage}
              onRender={handleRender}
              submission={submissionData}
            />
          </div>
          {isPoliciesAttachedPanelEnabled ? (
            <PoliciesAttachedViewer
              policyList={policyList}
              submission={submissionData}
            />
          ) : null}
          <div id="interviewMenu" data-testid="interviewMenu">
            <Dropdown
              as={ButtonGroup}
              key="interviewMenuDropDown"
              id="interviewMenuDropDown"
              data-testid="interviewMenuDropDown"
              variant="primary"
              drop="up-centered"
            >
              <Dropdown.Toggle
                key="interviewMenuDropDownToggle"
                id="interviewMenuDropDownToggle"
                data-testid="interviewMenuDropDownToggle"
                variant="btn btn-primary custom-btn-primary"
              >
                Interview Menu
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!environmentData.environment.toLowerCase().includes('prod') ? (
                  <div>
                    {baselineFiles ? addBaselineFillDropdownItems() : null}
                    <Dropdown.Item
                      eventKey="1"
                      id="policiesAttachedViewDropDownItem1"
                      data-testid="policiesAttachedViewDropDownItem1"
                      onClick={() => {
                        setIsPoliciesAttachedPanelEnabled();
                      }}
                    >
                      {isPoliciesAttachedPanelEnabled
                        ? 'Close Policies Attached Viewer'
                        : 'Open Policies Attached Viewer'}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey="2"
                      id="policiesAttachedViewDropDownItem2"
                      data-testid="policiesAttachedViewDropDownItem2"
                      onClick={() => {
                        isSampleDSA
                          ? handleShowIsSampleDSADialog()
                          : setIsSampleDSA((isSampleDSA) => !isSampleDSA);
                      }}
                    >
                      {isSampleDSA
                        ? 'Remove DSA SAMPLE watermark'
                        : 'Add DSA SAMPLE watermark'}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </div>
                ) : null}
                <Dropdown.Item
                  id="resetInterviewFormDropDownItem"
                  data-testid="resetInterviewFormDropDownItem"
                  eventKey="3"
                  onClick={() => {
                    handleShowClearFormDialog();
                  }}
                >
                  {`Reset ${registrationTypeFromURL.charAt(0).toUpperCase() + registrationTypeFromURL.slice(1)} Interview Form`}{' '}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="4"
                  id="exportToJsonDropDownItem"
                  data-testid="exportToJsonDropDownItem"
                  onClick={() => {
                    handleExportToJSONFile();
                  }}
                >
                  Export Data to JSON File
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="5"
                  id="importFromJsonDropDownItem"
                  data-testid="importFromJsonDropDownItem"
                  onClick={() => {
                    handleImportFromJSONFile();
                  }}
                >
                  Import Data from JSON File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Modal
            id="resetInterviewFormModal"
            data-testid="resetInterviewFormModal"
            show={showResetFormDialog}
            onHide={handleCloseFormDialog}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>{alertText}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseFormDialog}>
                Cancel
              </Button>
              <Button
                id="confirmResetInterviewFormModalBtn"
                data-testid="confirmResetInterviewFormModalBtn"
                variant="btn btn-primary custom-btn-primary"
                onClick={() => {
                  handleResetForm();
                  handleCloseFormDialog();
                }}
              >
                {`Reset ${registrationTypeFromURL.charAt(0).toUpperCase() + registrationTypeFromURL.slice(1)} Interview Form`}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            id="isSampleDSAModal"
            data-testid="isSampleDSAModal"
            show={showIsSampleDSADialog}
            onHide={handleCloseIsSampleDSADialog}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Remove Sample Watermark</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                'Do not use DSAs generated without the SAMPLE watermark for non-testing purposes.'
              }
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseIsSampleDSADialog}
              >
                Cancel
              </Button>
              <Button
                id="confirmIsSampleDSABtn"
                data-testid="confirmIsSampleDSABtn"
                variant="btn btn-primary custom-btn-primary"
                onClick={() => {
                  setIsSampleDSA((isSampleDSA) => !isSampleDSA);
                  handleCloseIsSampleDSADialog();
                }}
              >
                {'Remove SAMPLE Watermark'}
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer leftHandText={formVersion} />
        </div>
      )}
    </div>
  );
};

export default Interview;
