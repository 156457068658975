import React, { useState, useContext, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Spinner,
  InputGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { postRedmine } from '../redmine-utils.js';
import detectPlatform from '../detect-platform.js';
import PropTypes from 'prop-types';
import EnvironmentContext from './EnvironmentContext.js';
import RedmineConfigContext from './RedmineConfigContext.js';
import CommonSettingsContext from './CommonSettingsContext.js';
import { getPCTStatusFromSubmissionData } from '../global-utils.js';
import SuccessScreen from './SuccessScreen.js';

function HelpForm(props) {
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [helpRequest, setHelpRequest] = useState('');
  const [alertType, setAlertType] = useState('danger');
  const [alertHeading, setAlertHeading] = useState('');
  const [alertText, setAlertText] = useState('');
  const [formSummary, setFormSummary] = useState({});
  const [successfulSubmission, setSuccessSubmission] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSecure, setIsSecure] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isBackdropEnabled, setIsBackdropEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectAgencyOther, setSelectedAgencyOther] = useState('');
  const [closeButtonLabel, setCloseButtonLabel] = useState('Cancel');

  const { environmentData, pctVersion, userDN } =
    useContext(EnvironmentContext);
  const { commonSettingsData } = useContext(CommonSettingsContext);
  const { redmineConfigs, redmineSettings, isRedmineUnreachable } =
    useContext(RedmineConfigContext);

  const helpRequestOptions = [
    { request: 'Policy Assistance', value: 'policy' },
    { request: 'Onboarding Assistance', value: 'onboarding' },
    { request: 'Other', value: 'other' },
  ];

  const agencies = [
    { label: 'AFRICOM', value: 'AFRICOM' },
    { label: 'Air Force', value: 'Air Force' },
    { label: 'AMC', value: 'AMC' },
    { label: 'Army', value: 'Army' },
    { label: 'CENTCOM', value: 'CENTCOM' },
    { label: 'CIA', value: 'CIA' },
    { label: 'Coast Guard', value: 'Coast Guard' },
    { label: 'CYBERCOM', value: 'CYBERCOM' },
    { label: 'DARPA', value: 'DARPA' },
    { label: 'DAU', value: 'DAU' },
    { label: 'DCAA', value: 'DCAA' },
    { label: 'DCMA', value: 'DCMA' },
    { label: 'DFAS', value: 'DFAS' },
    { label: 'DHA', value: 'DHA' },
    { label: 'DHS', value: 'DHS' },
    { label: 'DIA', value: 'DIA' },
    { label: 'DISA', value: 'DISA' },
    { label: 'DLA', value: 'DLA' },
    { label: 'DMA', value: 'DMA' },
    { label: 'DMDC', value: 'DMDC' },
    { label: 'DMEA', value: 'DMEA' },
    { label: 'DNI', value: 'DNI' },
    { label: 'DOE', value: 'DOE' },
    { label: 'DPPA', value: 'DPPA' },
    { label: 'DSS', value: 'DSS' },
    { label: 'DTIC', value: 'DTIC' },
    { label: 'DTRA', value: 'DTRA' },
    { label: 'EUCOM', value: 'EUCOM' },
    { label: 'F-35 JPO', value: 'F-35 JPO' },
    { label: 'FBI', value: 'FBI' },
    { label: 'HPCMP', value: 'HPCMP' },
    { label: 'JAIC', value: 'JAIC' },
    { label: 'JALBTCX', value: 'JALBTCX' },
    { label: 'JFHQ', value: 'JFHQ' },
    { label: 'Joint Staff', value: 'Joint Staff' },
    { label: 'JTNC', value: 'JTNC' },
    { label: 'Marines', value: 'Marines' },
    { label: 'MDA', value: 'MDA' },
    { label: 'NASA', value: 'NASA' },
    { label: 'National Guard', value: 'National Guard' },
    { label: 'Navy', value: 'Navy' },
    { label: 'NGA', value: 'NGA' },
    { label: 'NMEC', value: 'NMEC' },
    { label: 'NORTHCOM', value: 'NORTHCOM' },
    { label: 'NRO', value: 'NRO' },
    { label: 'NSA', value: 'NSA' },
    { label: 'OSD', value: 'OSD' },
    { label: 'PACOM', value: 'PACOM' },
    { label: 'SDA', value: 'SDA' },
    { label: 'SNL', value: 'SNL' },
    { label: 'SOCOM', value: 'SOCOM' },
    { label: 'SOUTHCOM', value: 'SOUTHCOM' },
    { label: 'Space Force', value: 'Space Force' },
    { label: 'SPACECOM', value: 'SPACECOM' },
    { label: 'STRATCOM', value: 'STRATCOM' },
    { label: 'TRANSCOM', value: 'TRANSCOM' },
    { label: 'Other', value: 'Other' },
  ];

  const getProjectNameAndId = () => {
    let projectId = 0;
    let projectName = '';
    if (environmentData.environment === 'dev-no-redmine') {
      projectName = 'Dev Local Test Data';
      projectId = redmineSettings.projects[projectName].id;
    } else {
      projectName = environmentData.redmineProjectName;
      projectId = redmineSettings.projects[projectName].id;
    }

    return [projectName, projectId];
  };

  const getIssueDataFromFormFields = (event) => {
    const isOtherSelected = selectedAgency === 'Other';
    const requestorAgency = isOtherSelected
      ? selectAgencyOther
      : selectedAgency;

    const platform = detectPlatform();

    const issue_data = {};

    issue_data['subject'] =
      `${event.target.elements[0].selectedOptions[0].label} Help Request from ${event.target.elements.name.value} at ${requestorAgency}`;

    const phoneMaskName = isSecure ? 'Secure' : 'Comm.';

    const repPhone = `${phoneMaskName}: ${event.target.elements.phone.value}`;

    issue_data['custom_fields'] = {
      requestCategory: event.target.elements[0].selectedOptions[0].label,
      organization: requestorAgency,
      repName: event.target.elements.name.value,
      repPhone: repPhone,
      repEmail: event.target.elements.email.value,
      message: event.target.elements.message.value,
      browser: platform.name + ' v' + platform.version,
      os: platform.os,
      pctVersion: pctVersion,
    };

    issue_data.custom_fields['pctVersion'] = pctVersion;

    const jsonDataDump = JSON.stringify(issue_data['custom_fields'], null, 2);
    const pctStatus = getPCTStatusFromSubmissionData(
      issue_data['custom_fields'],
    );

    issue_data.custom_fields['pctStatus'] = pctStatus;
    issue_data.custom_fields['jsonDataDump'] = jsonDataDump;
    issue_data.custom_fields['networkLabel'] = environmentData.networkLabel;

    issue_data.custom_fields['submitterName'] = userDN;

    return issue_data;
  };

  const createRedmineIssue = (issueData) => {
    const [projectName, projectId] = getProjectNameAndId();

    const redmineIssue =
      redmineConfigs.tracker_templates.pctHelpRequest.create_tracker({
        redmineProjectSettings: redmineSettings.projects[projectName],
        issueData: issueData,
      });

    redmineIssue['project_id'] = projectId;

    return redmineIssue;
  };

  const postRedmineIssue = async (redmineIssue) => {
    const response = await postRedmine(
      {
        issue: redmineIssue,
        redmineIssuesEndpoint: redmineConfigs.redmineApiIssuesEndpoint,
      },
      environmentData.environment,
    );

    if (!response.ok) {
      throw new Error(response.status);
    }

    return await response.json();
  };

  const formSubmitSuccess = (event, responseJson) => {
    const isOtherSelected = selectedAgency === 'Other';
    const requestorAgency = isOtherSelected
      ? selectAgencyOther
      : selectedAgency;

    const phoneMaskName = isSecure ? 'Secure' : 'Comm.';

    const repPhone = `${phoneMaskName}: ${event.target.elements.phone.value}`;

    const form = event.target;

    setAlertText(
      commonSettingsData.helpFormSubmitSuccess +
        '\n Reference ID #' +
        responseJson.issue.id,
    );

    setFormSummary({
      requestCategory: event.target.elements[0].selectedOptions[0].label,
      organization: requestorAgency,
      repName: event.target.elements.name.value,
      repPhone: repPhone,
      repEmail: event.target.elements.email.value,
      message: event.target.elements.message.value,
    });

    setFormData({
      name: event.target.elements.name.value,
      email: event.target.elements.email.value,
      message: event.target.elements.message.value,
    });

    setSuccessSubmission(true);

    form.reset();
  };

  const submitFormDataToRedmine = async (event) => {
    const issueData = getIssueDataFromFormFields(event);
    const redmineIssue = createRedmineIssue(issueData);

    try {
      const redmineIssueDetails = await postRedmineIssue(redmineIssue);

      formSubmitSuccess(event, redmineIssueDetails);
    } catch (error) {
      console.error('Unable to submit form data to Redmine: ', error);

      // Don't use ModalAlert here because we want don't want a modal on top of a modal.
      setAlertHeading('Error');
      setAlertType('danger');
      setAlertText(commonSettingsData.helpFormSubmitError);
      setShowAlert(true);
      setCloseButtonLabel('Cancel');
      setSubmitDisabled(false);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    event.preventDefault();

    setSubmitDisabled(true);
    setLoading(true);

    if (isRedmineUnreachable) {
      console.error(
        'RedmineError: Unable to make submission to Redmine either due to missing Redmine configuration data or cannot connect to Redmine',
      );
      event.stopPropagation();
      setAlertHeading('Error');
      setAlertType('danger');
      setAlertText(commonSettingsData.helpFormSubmitError);
      setShowAlert(true);
    } else if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
    } else {
      event.preventDefault();

      await submitFormDataToRedmine(event);
    }

    setSubmitDisabled(false);
    setLoading(false);
  };

  //Enables the backdrop for each handle function
  const enableBackdrop = (value) => {
    return value ? setIsBackdropEnabled(true) : setIsBackdropEnabled(false);
  };

  const handleHelpRequest = (event) => {
    const value = event.target.value;
    setHelpRequest(value);
    enableBackdrop(value);
  };

  const handleNewRequest = () => {
    setValidated(false);
    setShowAlert(false);
    setAlertType('danger');
    setAlertHeading('');
    setAlertText('');
    setSubmitDisabled(false);
    setFormData({
      ...formData,
      message: '',
    });
    setHelpRequest('');
    setSuccessSubmission(false);
    setLoading(false);
    setIsSecure(false);
  };

  const formFields = (event) => {
    const { name, value } = event.target;
    //ensure that if user changes value, backdrop is still enabled
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fieldValues = Object.values(formData).some((value) => value !== '');
    setIsBackdropEnabled(fieldValues);
  }, [formData]);

  const handleCheckBox = (event) => {
    setIsSecure(event.target.checked);
    formatPhoneNumber(phoneNumber, event.target.checked);
  };

  const formatPhoneNumber = (inputValue, isSecure) => {
    //Regex for valid phone number
    const onlyDigits = inputValue.replace(/\D/g, '');
    let formatter = '';
    let digitLength = null;

    if (isSecure) {
      digitLength = 7;
    } else {
      digitLength = 10;
    }

    for (let i = 0; i < onlyDigits.length && i < digitLength; i++) {
      if (!isSecure) {
        if (i === 3 || i === 6) {
          formatter += '-';
        }
        formatter += onlyDigits[i];
      } else {
        if (i === 3) {
          formatter += '-';
        }
        formatter += onlyDigits[i];
      }
    }

    setPhoneNumber(formatter);
    enableBackdrop(formatter);
  };

  const checkPhoneInput = (event) => {
    formatPhoneNumber(event.target.value, isSecure);
  };

  const handleModalCloseWithReset = () => {
    // Perform additional tasks here, such as resetting the modal state
    // Close modal popup, but first reset state of modal
    setValidated(false);
    setShowAlert(false);
    setAlertType('danger');
    setAlertHeading('');
    setAlertText('');
    setSubmitDisabled(false);
    setPhoneNumber('');
    setSelectedAgency('');
    setSelectedAgencyOther('');
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setHelpRequest('');
    setSuccessSubmission(false);
    setLoading(false);
    setIsSecure(false);
    // Call the original handleModalClose function
    props.handleModalOpen();
  };

  const handleAgencyOther = (event) => {
    const value = event.target.value;
    setSelectedAgencyOther(value);
    enableBackdrop(value);
  };

  const handleAgency = (event) => {
    const value = event.target.value;
    setSelectedAgency(event.target.value);
    enableBackdrop(value);
  };

  const checkTextForHelpEmail = () => {
    const helpDeskRegex = /({{pctHelpDeskEmail}})/g;
    const { environmentData } = useContext(EnvironmentContext); // Ensure you have access to environmentData

    if (
      environmentData &&
      alertText &&
      alertText.includes('{{pctHelpDeskEmail}}')
    ) {
      const email = environmentData.pctHelpDeskEmail;
      const parts = alertText.split(helpDeskRegex);
      const elements = parts.map((part, i) => {
        if (part === '{{pctHelpDeskEmail}}') {
          return (
            <Alert.Link key={i} href={`mailto:${email}`}>
              {email}
            </Alert.Link>
          );
        } else {
          return <span key={i}>{part}</span>;
        }
      });
      return elements;
    }

    return alertText;
  };

  return (
    <div id="helpform" data-testid="helpForm">
      {successfulSubmission ? (
        <SuccessScreen
          show={successfulSubmission}
          onHide={handleModalCloseWithReset}
          onNewRequest={handleNewRequest}
          formSummary={formSummary}
          message={alertText}
        />
      ) : (
        <Modal
          // eslint-disable-next-line
          show={props.modalOpen}
          backdrop={isBackdropEnabled ? 'static' : true}
          onHide={handleModalCloseWithReset}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="text-primary">Contact Us</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert
              show={showAlert}
              variant={alertType}
              onClose={() => {
                setShowAlert(false);
              }}
              dismissible
              data-testid="pctHelpFormAlert"
            >
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{checkTextForHelpEmail()}</p>
            </Alert>
            <p className="text-center">
              Feel free to contact us using the form below if you need any
              assistance or have any questions about the onboarding process.
              <br />A CDF Representative will contact you shortly.
            </p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="validationCustom01">
                <Form.Select
                  value={helpRequest}
                  onChange={handleHelpRequest}
                  className="mb-2"
                  required
                  data-testid="issue-select"
                >
                  <option value="">Select type of Issue</option>
                  {helpRequestOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.request}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid" className="mb-1">
                  Please select an issue type
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Select
                  value={selectedAgency}
                  className="mb-2"
                  onChange={handleAgency}
                  required
                  data-testid="agency-select"
                >
                  <option value="">Select an Agency</option>
                  {agencies.map((agency, index) => (
                    <option key={index} value={agency.value}>
                      {agency.label}
                    </option>
                  ))}
                </Form.Select>
                {selectedAgency === 'Other' && (
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="other_agency"
                      placeholder="Agency"
                      value={selectAgencyOther}
                      onChange={handleAgencyOther}
                      className="mb-1"
                      data-testid="other-agency"
                      required
                    />
                    <Form.Control.Feedback type="invalid" className="mb-1">
                      Please enter an agency.
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                <Form.Control.Feedback type="invalid" className="mb-1">
                  Please select an agency.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="name"
                  type="name"
                  id="name"
                  value={formData.name}
                  onChange={formFields}
                  className="mb-1"
                  placeholder="Name"
                  required
                />
                <Form.Control.Feedback type="invalid" className="mb-1">
                  Please enter a name.
                </Form.Control.Feedback>
              </Form.Group>
              <InputGroup.Text className="mb-1">
                <Form.Group>
                  <Form.Control
                    type="tel"
                    id="phone"
                    placeholder="Phone"
                    value={phoneNumber}
                    onChange={checkPhoneInput}
                    required
                    data-testid="phone-number"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mb-1 invalid-phone-text"
                  >
                    Please enter a phone number
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Check
                  type="switch"
                  label="Comm. or Secure"
                  id="phoneMaskSwitch"
                  onChange={handleCheckBox}
                  data-testid="phoneMaskSwitch"
                  className="phoneTypeCheckbox"
                />
              </InputGroup.Text>
              <Form.Group>
                <Form.Control
                  name="email"
                  type="email"
                  id="email"
                  className="mb-1"
                  value={formData.email}
                  onChange={formFields}
                  placeholder="Email"
                  required
                />
                <Form.Control.Feedback type="invalid" className="mb-1">
                  Please provide an email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="message"
                  type="text"
                  id="message"
                  className="mb-1"
                  value={formData.message}
                  onChange={formFields}
                  placeholder="Message"
                  as="textarea"
                  rows={5}
                  required
                />
                <Form.Control.Feedback type="invalid" className="mb-1">
                  Please provide a brief message describing your question or
                  issue.
                </Form.Control.Feedback>
              </Form.Group>
              <div
                style={{ marginTop: '15px' }}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <Button
                    variant="btn btn-primary custom-btn-primary"
                    type="submit"
                    className="me-2"
                    data-testid="submitBtn"
                    disabled={isSubmitDisabled}
                  >
                    {loading ? (
                      <Spinner
                        className=".spinner-border-sm"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleModalCloseWithReset}
                  >
                    {closeButtonLabel}
                  </Button>
                </div>
                <OverlayTrigger
                  placement="top"
                  trigger="click"
                  rootClose
                  overlay={
                    <Tooltip id="version-tooltip">
                      PCT Version: {pctVersion}
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faCircleInfo} size="2x" />
                </OverlayTrigger>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

HelpForm.propTypes = {
  handleModalOpen: PropTypes.func.isRequired,
};

export default HelpForm;
