import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import PropTypes from 'prop-types';
import { useContext, useState, useEffect } from 'react';
import { faRocket, faBook, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EnvironmentContext from './EnvironmentContext';

const HTMLDisplay = (props) => {
  const [parsed, setParsed] = useState(null);

  const { isEnvironmentDataLoaded, environmentData } =
    useContext(EnvironmentContext);

  //handle font awesome icons by mapping their ids
  const faAwesomeIcons = {
    'font-awesome-icon-rocket': faRocket,
    'font-awesome-book': faBook,
    'font-awesome-icon-phone': faPhone,
  };

  useEffect(() => {
    //transforms specific elements
    const options = {
      // Replaces domNode for anchor tag to a React Node to handle onClick()
      replace: ({ attribs, children }) => {
        if (attribs && attribs.id === 'contactCDFLink') {
          return (
            <a
              onClick={props.handleModalOpen}
              className="text-decoration-none  handle-modal-open-btn"
            >
              {/* conversion of html elements back to react elements 
               for nested content */}
              {domToReact(children, options)}
            </a>
          );
        }

        if (attribs && attribs.id && faAwesomeIcons[attribs.id]) {
          return (
            <FontAwesomeIcon
              icon={faAwesomeIcons[attribs.id]}
              size="lg"
              color="#ed7d31ff"
            />
          );
        }

        if (attribs?.id === 'aboutCDFLink') {
          return environmentData.links?.['About CDF'] ? (
            <a
              href={environmentData.links['About CDF']}
              className="text-decoration-none  about-cdf-open-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              {domToReact(children, options)}
            </a>
          ) : (
            <a
              href="./learn"
              className="text-decoration-none  about-cdf-open-btn"
            >
              {' '}
              {domToReact(children, options)}
            </a>
          );
        }
      },
    };

    const parsedHTML = parse(props.html, options);
    setParsed(parsedHTML);
  }, [props.html, isEnvironmentDataLoaded]);
  return <div data-testid={'conversion-test'}>{parsed}</div>;
};

HTMLDisplay.propTypes = {
  html: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func,
};

export default HTMLDisplay;
