export const ClassificationTypes = {
  UNCLASSIFIED: { priority: 1, stringValue: 'UNCLASSIFIED' },
  FOUO: { priority: 2, stringValue: 'UNCLASSIFIED/FOUO' },
  CUI: { priority: 3, stringValue: 'CUI' },
  CONFIDENTIAL: { priority: 4, stringValue: 'CONFIDENTIAL' },
  SECRET: { priority: 5, stringValue: 'SECRET' },
  TOPSECRET: { priority: 6, stringValue: 'TOP SECRET' },
};

export function compareClassifications(classification1, classification2) {
  const priority1 = classification1.priority;
  const priority2 = classification2.priority;

  if (priority1 < priority2) {
    return -1;
  } else if (priority1 > priority2) {
    return 1;
  } else {
    return 0;
  }
}

export function findClassificationTypeByStringValue(searchString) {
  // The for loop in this function does not work when FOUO is used. Need
  // a special condition to catch this case.
  if (searchString === 'UNCLASSIFIED/FOUO') {
    return ClassificationTypes['FOUO'];
  }

  for (const [key, value] of Object.entries(ClassificationTypes)) {
    if (searchString.startsWith(value.stringValue)) {
      // Use startsWith because we only care about the classification,
      // not the releasability when comparing classifications level.
      return ClassificationTypes[key];
    }
  }
  return null;
}
