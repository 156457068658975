import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const OnboardingStateContext = createContext();

export const OnboardingStateProvider = ({ children }) => {
  const onboardStateString = localStorage.getItem('onboardingStateMatrix');
  const onBoardingStateMatrixFromMemory = JSON.parse(onboardStateString);

  const lastSubmittedIssueIdString = localStorage.getItem(
    'lastSubmittedIssueId',
  );
  const lastSubmittedIssueFromMemory = JSON.parse(lastSubmittedIssueIdString);

  const defaultMatrix = [
    [0, 0, 0],
    [0, 0, 0],
  ];

  const [onboardingStateMatrix, setOnboardingStateMatrix] = useState(
    onBoardingStateMatrixFromMemory || defaultMatrix,
  );

  const [lastSubmittedIssueId, setLastSubmittedIssueId] = useState(
    lastSubmittedIssueFromMemory || '0000',
  );

  useEffect(() => {
    const asString = JSON.stringify(onboardingStateMatrix);
    localStorage.setItem('onboardingStateMatrix', asString);
  }, [onboardingStateMatrix]);

  useEffect(() => {
    const asString = JSON.stringify(lastSubmittedIssueId);
    localStorage.setItem('lastSubmittedIssueId', asString);
  }, [lastSubmittedIssueId]);

  return (
    <OnboardingStateContext.Provider
      value={{
        onboardingStateMatrix,
        setOnboardingStateMatrix,
        lastSubmittedIssueId,
        setLastSubmittedIssueId,
      }}
    >
      {children}
    </OnboardingStateContext.Provider>
  );
};

OnboardingStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OnboardingStateContext;
