import React, { useContext, useEffect, useState } from 'react';
import EnvironmentContext from './EnvironmentContext';

function ClassificationBanner() {
  const { environmentData, isQueryingEnvironmentDataLoaded } =
    useContext(EnvironmentContext);

  const [backgroundColor, setBackgroundColor] = useState('#808080');
  const [textColor, setTextColor] = useState('#000000');
  const [bannerDisplay, setBannerDisplay] = useState('Loading banner...');

  useEffect(() => {
    if (!isQueryingEnvironmentDataLoaded) {
      if (
        environmentData === undefined ||
        environmentData.bannerBackgroundColor === undefined
      ) {
        setBackgroundColor('#808080');
      } else {
        setBackgroundColor(environmentData.bannerBackgroundColor);
      }

      if (
        environmentData === undefined ||
        environmentData.bannerTextColor === undefined
      ) {
        setTextColor('#000000');
      } else {
        setTextColor(environmentData.bannerTextColor);
      }

      if (
        environmentData === undefined ||
        environmentData.bannerDisplay === undefined
      ) {
        setBannerDisplay('ERROR: Classification data not found');
      } else {
        setBannerDisplay(environmentData.bannerDisplay);
      }
    }
  }, [isQueryingEnvironmentDataLoaded]);

  return (
    <div
      id="classificationBanner"
      style={{
        color: textColor,
        background: backgroundColor,
        textAlign: 'center',
      }}
    >
      <strong>{bannerDisplay}</strong>
    </div>
  );
}

export default ClassificationBanner;
