import { Spinner } from 'react-bootstrap';

const Indicator = () => {
  return (
    <div
      id="loadingIndicator"
      data-testid="loadingIndicator"
      className="spinner"
    >
      <Spinner
        clasanimation="border"
        className="spinner-border"
        variant="btn btn-primary custom-btn-primary"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
export default Indicator;
