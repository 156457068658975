import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import EnvironmentContext from './EnvironmentContext';

const Start = () => {
  const { isEnvironmentDataLoaded } = useContext(EnvironmentContext);

  const navigate = useNavigate();

  return (
    <div id="onboardStart" data-testid="onboardStart">
      <Container id="onBoardStartContainer">
        <Row id="cdfOnboardingProcessImageRow">
          <img
            src="/content/images/onboarding-3step-new.svg"
            id="onBoardImage"
            alt="Image of onboarding process"
          />
        </Row>
        <Row id="startButtonRow">
          <Col xs={12} md={6} className="d-flex justify-content-center mb-4">
            {' '}
            <Button
              data-testid="startRegistrationConsumerButton"
              disabled={false}
              id="startRegistrationConsumerButton"
              className="startBtns btn btn-primary custom-btn-primary"
              onClick={() => {
                navigate('/onboard/consumer/registration');
              }}
            >
              Start Consumer Onboarding
            </Button>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center mb-2">
            {' '}
            <Button
              data-testid="startRegistrationProviderButton"
              disabled={false}
              id="startRegistrationProviderButton"
              className="startBtns btn btn-primary custom-btn-primary"
              onClick={() => {
                navigate('/onboard/provider/registration');
              }}
            >
              Start Provider Onboarding
            </Button>
          </Col>
        </Row>
        {isEnvironmentDataLoaded ? (
          <>
            <Row id="policyPreviewDownloadRow">
              <p className="headers">
                <img
                  src="/content/images/cdf-waffle.svg"
                  style={{ width: '30px' }}
                  className="d-inline-block pb-2 me-1"
                  alt="CDF Logo"
                />{' '}
                Preview the Policy Interview
              </p>
            </Row>
            <Row id="previewButtonRow">
              <Col
                xs={12}
                md={6}
                className="d-flex justify-content-center mb-4"
              >
                <Button
                  data-testid="previewConsumerInterviewButton"
                  id="previewConsumerInterviewButton"
                  disabled={false}
                  className="startBtns btn btn-secondary"
                  onClick={() => {
                    navigate('/onboard/consumer/previewinterview');
                  }}
                >
                  Consumer Interview Preview
                </Button>
              </Col>
              <Col
                xs={12}
                md={6}
                className="d-flex justify-content-center mb-2"
              >
                <Button
                  data-testid="previewProviderInterviewButton"
                  id="previewProviderInterviewButton"
                  disabled={false}
                  className="startBtns btn btn-secondary"
                  onClick={() => {
                    navigate('/onboard/provider/previewinterview');
                  }}
                >
                  Provider Interview Preview
                </Button>
              </Col>
            </Row>
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default Start;
