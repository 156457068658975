import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import HelpForm from './HelpForm';

const HelpButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div>
      <div id="helpButton" onClick={handleClick}>
        <FontAwesomeIcon icon={faCircleQuestion} />
        <span id="helpButton-text">Help</span>
      </div>
      <HelpForm modalOpen={modalOpen} handleModalOpen={handleClick} />
    </div>
  );
};

export default HelpButton;
