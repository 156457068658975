import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './components/App.js';
import { ModalAlertQueue } from './components/ModalAlertQueue.js';
import { EnvironmentProvider } from './components/EnvironmentContext.js';
import { CommonSettingsProvider } from './components/CommonSettingsContext.js';
import { RedmineConfigProvider } from './components/RedmineConfigContext.js';

import './styles.scss';

const container = document.getElementById('app');

const root = createRoot(container);

root.render(
  <div>
    <Router
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <EnvironmentProvider>
        <CommonSettingsProvider>
          <RedmineConfigProvider>
            <ModalAlertQueue>
              <App />
            </ModalAlertQueue>
          </RedmineConfigProvider>
        </CommonSettingsProvider>
      </EnvironmentProvider>
    </Router>
  </div>,
);
