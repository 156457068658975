export const postRedmine = (issueSettings, environment) => {
  const RedmineURL = issueSettings.redmineIssuesEndpoint;

  const issue = {
    is_private: '0',
    subject: '',
    description: '',
    status_id: '1',
    priority_id: '1',
    ...issueSettings.issue,
  };

  const issueData = JSON.stringify({
    issue: issue,
  });

  let myHeaders = new Headers();

  // Redmine API Key is injected via proxy server
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: issueData,
    redirect: 'follow',
  };

  // If running in development mode (localhost) use mocked fetch
  if (environment === 'dev-no-redmine') {
    return fetchMock(RedmineURL, requestOptions);
  }

  return fetch(RedmineURL, requestOptions);
};

export async function queryRedmine(endpoint) {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const options = {
    retries: 3,
    delay: 1000,
  };

  for (let i = 0; i < options.retries; i++) {
    try {
      const response = await fetch(endpoint, { headers });
      if (!response.ok) {
        throw new Error(`Response failed with status of, ${response.status}`);
      }

      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Fetch failed retrying...');
      // if all retries have been exhausted
      if (i === options.retries - 1) {
        throw new Error('retry attempts failed');
      }
      //delay before retrying
      await new Promise((resolve) => setTimeout(resolve, options.delay));
    }
  }
}

export const fetchMock = (RedmineURL, requestOptions, throwError = false) => {
  console.log(
    `postRedmine Fetch Values:\nRedmineURL: ${RedmineURL}\nrequestOptions: ${JSON.stringify(requestOptions)}`,
  );

  if (throwError) {
    return Promise.resolve({
      ok: false,
      status: 500,
      statusText: 'Server error',
      json: () => Promise.resolve({ message: 'Server error' }),
    });
  } else {
    return Promise.resolve({
      ok: true,
      json: () => Promise.resolve({ issue: { id: '0000' } }),
    });
  }
};

export const uploadPdfToRedmineIssue = async (
  redmineIssueId,
  pdfBuffer,
  fileName,
) => {
  try {
    const uploadResponse = await fetch('/redmine/uploads.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: pdfBuffer,
    });

    if (!uploadResponse.ok) {
      throw new Error(
        `Failed to Upload PDF to Redmine: ${uploadResponse.statusText}`,
      );
    }

    const data = await uploadResponse.json();

    const uploadToken = data.upload.token;

    const attachResponse = await fetch(
      `/redmine/issues/${redmineIssueId}.json`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          issue: {
            uploads: [
              {
                token: uploadToken,
                filename: fileName,
                description: 'Initial DSA',
                content_type: 'application/pdf',
              },
            ],
          },
        }),
      },
    );

    if (!attachResponse.ok) {
      throw new Error(`Failed to attach PDF: ${attachResponse.statusText}`);
    }

    return true;
  } catch (error) {
    console.error('Error uploading PDF to Redmine:', error.message);

    return false;
  }
};
