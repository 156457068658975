import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import PDFGenerator from '../PDFGenerator';
import Indicator from './Indicator.js';
import EnvironmentContext from './EnvironmentContext';

const PreviewInterview = () => {
  const [interviewPreviewPDF, setInterviewPreviewPDF] = useState('');
  const [downloadButtonFileName, setDownloadButtonFileName] = useState('');
  const [isGeneratingPreviewPDF, setIsGeneratingPreviewPDF] = useState(true);
  const [didPreviewFailToGenerate, setDidPreviewFailToGenerate] =
    useState(false);

  const navigate = useNavigate();

  const { registrationTypeFromURL } = useParams();

  const { environmentData, pctVersion, configsPath } =
    useContext(EnvironmentContext);

  const handleDownload = async () => {
    try {
      const response = await fetch(interviewPreviewPDF);

      if (!response.ok) {
        throw new Error('Unable to fetch interview PDF');
      }

      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = downloadButtonFileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the interview preview file.');
    }
  };

  useEffect(() => {
    const generateInterviewPreviewPDF = async () => {
      const generate = async () => {
        const pdfGenerator = new PDFGenerator({
          environmentData: environmentData,
          registrationType: registrationTypeFromURL,
          pctVersion: pctVersion,
          configsPath: configsPath,
        });

        try {
          const [dlInterviewPdfBlob, interviewPreviewFileName] =
            await pdfGenerator.generatePDF({
              type: 'dl-interview',
              data: null,
            });

          const fileURL = window.URL.createObjectURL(dlInterviewPdfBlob);
          setDownloadButtonFileName(interviewPreviewFileName);

          setInterviewPreviewPDF(fileURL);

          setIsGeneratingPreviewPDF(false);
        } catch (error) {
          setDidPreviewFailToGenerate(true);
          console.error('Error generating Downloadable Interview PDF.');
        }
      };

      await generate();
    };

    generateInterviewPreviewPDF();
  }, []);

  return (
    <Container
      data-testid="previewInterviewContainer"
      id="previewInterviewContainer"
      fluid
    >
      <h1 id="previewInterviewHeader">
        <img
          src="/content/images/cdf-waffle.svg"
          style={{ width: '30px' }}
          className="d-inline-block pb-2 me-1"
          alt="CDF Logo"
        />{' '}
        {`${registrationTypeFromURL.charAt(0).toUpperCase() + registrationTypeFromURL.slice(1)} Policy Interview Preview`}
      </h1>
      <Row
        id="previewInterviewNavButtonsRow"
        className="justify-content-center mt-2"
      >
        <Col
          id="returnToPrepareBtnCol"
          xs={12}
          md={4}
          className="d-flex justify-content-center justify-content-md-start mb-2"
        >
          <Button
            data-testid="returnToPrepareBtn"
            disabled={false}
            id="returnToPrepareBtn"
            className="previewInterviewNavBtns w-75 custom-btn-primary"
            onClick={() => {
              navigate('/onboard');
            }}
          >
            Return to Prepare Page
          </Button>
        </Col>
        <Col
          id="downloadBtnCol"
          xs={12}
          md={4}
          className="d-flex justify-content-center justify-content-md-start mb-2"
        >
          <Button
            data-testid="downloadInterviewPreviewBtn"
            disabled={false}
            id="downloadInterviewPreviewBtn"
            className="previewInterviewNavBtns w-75 custom-btn-primary"
            onClick={handleDownload}
          >
            {'Download Interview Preview'}
          </Button>
        </Col>
        <Col
          id="startOnboardingBtnCol"
          xs={12}
          md={4}
          className="d-flex justify-content-center justify-content-md-start mb-2"
        >
          <Button
            data-testid="goToRegistrationFromPreviewBtn"
            disabled={false}
            id="goToRegistrationFromPreviewBtn"
            className="previewInterviewNavBtns w-75 custom-btn-primary"
            onClick={() => {
              navigate(`/onboard/${registrationTypeFromURL}/registration`);
            }}
          >
            {`Start ${
              registrationTypeFromURL.charAt(0).toUpperCase() +
              registrationTypeFromURL.slice(1)
            } Onboarding`}
          </Button>
        </Col>
      </Row>
      {didPreviewFailToGenerate ? (
        <Row
          id="interviewPreviewPDFFailedRow"
          data-testid="interviewPreviewPDFFailedRow"
          className="justify-content-center mt-2"
        >
          <Col xs={12} className="d-flex justify-content-center">
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <div
                id="interviewPreviewUnavailableError"
                data-testid="interviewPreviewUnavailableError"
                className="error-message-box"
                style={{
                  backgroundColor: '#f8d7da',
                  padding: '20px 20px',
                  borderRadius: '10px',
                }}
              >
                <h2>Interview Preview Unavailable</h2>
                <p style={{ fontSize: '20px' }}>
                  Unable to open the interview preview. Please check your
                  internet connection and reload the page.
                  <br />
                  If the problem persists, contact the CDF Help Desk at&nbsp;
                  <a href={`mailto:${environmentData.pctHelpDeskEmail}`}>
                    {environmentData.pctHelpDeskEmail}
                  </a>
                </p>
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row
          id="interviewPreviewPDFViewerRow"
          data-testid="interviewPreviewPDFViewerRow"
          className="justify-content-center mt-2"
        >
          <Col xs={12} className="d-flex justify-content-center">
            {isGeneratingPreviewPDF ? (
              <Indicator />
            ) : (
              <iframe
                id="interviewPreviewPDFVieweriFrame"
                data-testid="interviewPreviewPDFVieweriFrame"
                src={interviewPreviewPDF}
                title="Interview Preview"
                className="pdf-iframe"
                style={{ width: '100%', height: '100vh', border: 'none' }}
              />
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default PreviewInterview;
