import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faBook, faPhone } from '@fortawesome/free-solid-svg-icons';
import HTMLDisplay from './HTMLDisplay';
import HelpForm from './HelpForm';
import Indicator from './Indicator';
import EnvironmentContext from './EnvironmentContext';

const Home = () => {
  // eslint-disable-next-line
  const [_, setPathname] = useState('/');
  const [htmlContent, sethtmlContent] = useState('');
  const [loading, setLoading] = useState(true);

  const { environmentData } = useContext(EnvironmentContext);

  const path = '/content/home-content/home.html';

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHtml = async () => {
      try {
        const response = await fetch(path);
        if (!response.ok) {
          throw new Error(`Http error: ${response.status}`);
        }
        const htmlData = await response.text();
        sethtmlContent(htmlData);
        setLoading(false);
      } catch (error) {
        console.error('Unable to fetch data', error);
        setLoading(false);
      }
    };
    fetchHtml();
  }, [path]);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  // Modal Help Form
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen((prevModalOpen) => !prevModalOpen);
  };

  if (loading) return <Indicator />;

  return (
    <>
      <Container id="home" data-testid="home">
        <Row
          id="homeButtonsRow"
          data-testid="homeButtonsRow"
          className="justify-content-center mt-2"
        >
          <Col
            id="startCDFOnboardingBtnCol"
            xs={12}
            md={4}
            className="d-flex justify-content-center justify-content-md-start mb-2"
          >
            <Button
              id="startCDFOnboardingBtn"
              data-testid="startCDFOnboardingBtn"
              className="home-nav-buttons w-75 custom-btn-primary"
              onClick={() => {
                navigate('/onboard');
              }}
            >
              <div id="font-awesome-icon-rocket">
                <FontAwesomeIcon icon={faRocket} size="lg" color="#ed7d31ff" />
                &nbsp;&nbsp;Start CDF Onboarding
              </div>
            </Button>
          </Col>
          <Col
            id="aboutCDFBtnCol"
            xs={12}
            md={4}
            className="d-flex justify-content-center justify-content-md-start mb-2"
          >
            {environmentData.links?.['About CDF'] ? (
              <Button
                id="aboutCDFBtn"
                data-testid="aboutCDFBtn"
                className="home-nav-buttons w-75 btn-secondary"
                onClick={() => {
                  window.open(
                    environmentData.links['About CDF'],
                    '_blank',
                    'noopener,noreferrer',
                  );
                }}
              >
                <div id="font-awesome-book">
                  <FontAwesomeIcon icon={faBook} size="lg" color="#ed7d31ff" />
                  &nbsp;&nbsp;&nbsp;Learn More About CDF
                </div>
              </Button>
            ) : (
              <Button
                id="aboutCDFBtn"
                data-testid="aboutCDFBtn"
                className="home-nav-buttons w-75 btn-secondary"
                onClick={() => {
                  navigate('/learn');
                }}
              >
                <div id="font-awesome-book">
                  <FontAwesomeIcon icon={faBook} size="lg" color="#ed7d31ff" />
                  &nbsp;&nbsp;&nbsp;Learn More About CDF
                </div>
              </Button>
            )}
          </Col>
          <Col
            id="contactCDFBtnCol"
            xs={12}
            md={4}
            className="d-flex justify-content-center justify-content-md-start mb-2"
          >
            <Button
              id="contactCDFBtn"
              data-testid="contactCDFBtn"
              className="home-nav-buttons w-75 btn-secondary"
              onClick={() => {
                handleModalOpen();
              }}
            >
              <div id="font-awesome-icon-phone">
                <FontAwesomeIcon icon={faPhone} size="lg" color="#ed7d31ff" />
                &nbsp;&nbsp;Contact CDF Representative
              </div>
            </Button>
          </Col>
        </Row>
        <Row
          id="homeHtmlContentRow"
          data-testid="homeHtmlContentRow"
          className="justify-content-center mt-2"
        >
          <HTMLDisplay html={htmlContent} />
          {modalOpen && (
            <HelpForm modalOpen={modalOpen} handleModalOpen={handleModalOpen} />
          )}
        </Row>
      </Container>
    </>
  );
};

export default Home;
