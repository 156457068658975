import PropTypes from 'prop-types';
import { Modal, Button, Alert } from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SuccessScreen({ show, onHide, onNewRequest, formSummary, message }) {
  return (
    <div id="successScreen">
      <Modal
        show={show}
        onHide={onHide}
        centered
        id="successScreenModal"
        data-testid="successModal"
        backdrop="static"
      >
        <Modal.Body>
          <Alert variant="success" id="successScreenAlert">
            <Alert.Heading id="successScreenAlertHeader">
              Thank you!
            </Alert.Heading>
            <p id="successScreenAlertMessage">{message}</p>
          </Alert>
          <div id="successScreenSummaryList">
            <ul>
              <h4 id="successScreenHeader">
                Help Request Submitted
                <span>
                  {' '}
                  <FontAwesomeIcon
                    id="successScreenAnimation"
                    icon={faCheckCircle}
                    size="1x"
                    color="black"
                  />
                </span>
              </h4>
              <li>
                <strong>Issue: </strong> &nbsp;
                {formSummary.requestCategory}
              </li>
              <li>
                <strong>Organization:</strong>&nbsp;{formSummary.organization}
              </li>
              <li>
                <strong>Representative:</strong>&nbsp;{formSummary.repName}
              </li>
              <li>
                <strong>Representative Number:</strong> &nbsp;
                {formSummary.repPhone}
              </li>
              <li>
                <strong>Representative Email:</strong>&nbsp;
                {formSummary.repEmail}
              </li>
              <p>
                <strong>Message:</strong>
                <br />
                {formSummary.message}
              </p>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer id="successScreenBtns">
          <Button
            id="successNewRequestBtn"
            data-testid="successNewRequestBtn"
            className="custom-btn-primary"
            onClick={onNewRequest}
          >
            New Request
          </Button>
          <Button
            id="successScreenCloseBtn"
            data-testid="successScreenCloseBtn"
            variant="secondary"
            onClick={onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

SuccessScreen.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onNewRequest: PropTypes.func,
  formSummary: PropTypes.object,
  message: PropTypes.string,
};

export default SuccessScreen;
